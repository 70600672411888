/** @format */

import styled from 'styled-components';

export const ExclusiveFormContainer = styled.div`
  border-radius: 1.5625rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  min-width: 70rem;
  padding: 1.56rem;

  .listing_header p {
    color: var(--White, #fafafc);
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: capitalize;
  }

  .cancel_button {
    border-radius: 0.5rem;
    background: var(--Darker-Grey---Fields-BG, #474747);
    padding: 0.625rem 1.25rem;
    margin-right: 1rem;
    cursor: pointer;
    flex: 1;
  }

  .cancel_button p {
    color: var(--Dark-Grey---Paragraph-Text, #999);
    text-align: center;
    font-family: Roboto;
    font-size: 1.125rem;
    font-weight: 400;
    margin: 0;
  }

  .save_button {
    padding: 0.625rem 1.25rem;
    border-radius: 0.5rem;
    background: #fff;
    cursor: pointer;
    flex: 1;
  }

  .save_button p {
    text-align: center;
    color: var(--Black, #13131a);
    font-family: Roboto;
    font-size: 1.125rem;
    font-weight: 400;
    margin: 0;
  }

  .exclusive_container_slider {
    width: 35rem;
  }
`;

export const FormContainer = styled.div`
  margin-left: 3rem;

  .input_container {
    p {
      color: var(--Dark-Grey---Paragraph-Text, #999);
      font-family: Roboto;
      font-size: 0.75rem;
      font-weight: 500;
      letter-spacing: 0.15rem;
      text-transform: uppercase;
      margin-bottom: 0.35rem;
    }
    .exclusive_input {
      border-radius: 0.5rem;
      background: var(--Active-Black, #232325);
      color: var(--Light-Grey---Input-Text, #d9d9d9);
      font-family: Roboto;
      font-size: 0.875rem;
      font-weight: 400;
      border: none;
      padding: 0.5rem;
      width: 100%;
    }
    .wrapper-class {
      padding: 0.625rem;
      border-radius: 0.5rem;
      background: var(--Active-Black, #232325);
      border: none;
      color: white;
    }
    .toolbar-class {
      background: var(--Active-Black, #232325);
      border: none;
    }
    .editor-class {
      max-height: 30rem;
      min-height: 25rem;
      overflow: scroll;
      overflow-x: hidden;
      scrollbar-width: thin;
    }
    .editor-class::-webkit-scrollbar {
      width: 5px;
      max-height: 2px;
    }

    .editor-class::-webkit-scrollbar-track {
      border-radius: 6.25rem;
      background: var(--Element-BG-Dark-Grey, #18181a);
    }

    .editor-class::-webkit-scrollbar-thumb {
      border-radius: 6.25rem;
      background: var(--Dark-Grey---Paragraph-Text, #999);
    }
  }
`;
