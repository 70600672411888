/** @format */

import LiveEdithTextHighLighter from '../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import LiveEditImageHighLighter from '../../../liveEditHighlighterstyles/liveEditImageHighLighter.component';
import {
  FooterContainer,
  FooterSocialMediaLinksContainer,
} from './footer.style';

export default function FooterT3({ data }) {
  return (
    <footer className="py-4">
      <div className="container">
        <hr />
        <div className="row text-center text-md-start align-items-center pt-0">
          <div className="col-12 col-md-3 mb-3 mb-md-0">
            <LiveEditImageHighLighter
              section={'footer'}
              type="img">
              <img
                src={'/public/' + data?.footer?.image}
                alt="logo"
                className="img-fluid ft-logo"
              />
            </LiveEditImageHighLighter>
          </div>
          <div className="col-12 col-md-6 mb-3 mb-md-0 text-center">
            <h5 className="mb-1">
              <LiveEdithTextHighLighter
                section="footer"
                type="text2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.footer?.text2,
                  }}></div>
              </LiveEdithTextHighLighter>
            </h5>
            <h6 className="mb-1">
              <LiveEdithTextHighLighter
                section="footer"
                type="text3">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.footer?.text3,
                  }}></div>
              </LiveEdithTextHighLighter>
            </h6>
            <p className="mb-0">
              <LiveEdithTextHighLighter
                section="footer"
                type="text4">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.footer?.text4,
                  }}></div>
              </LiveEdithTextHighLighter>
            </p>
            <p className="mb-0">
              <LiveEdithTextHighLighter
                section="footer"
                type="text5">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.footer?.text5,
                  }}></div>
              </LiveEdithTextHighLighter>
            </p>
            <small className="mb-0">
              <LiveEdithTextHighLighter
                section="footer"
                type="text6">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.footer?.text6,
                  }}></div>
              </LiveEdithTextHighLighter>
            </small>
          </div>
          <div className="col-12 col-md-3 d-flex justify-content-center justify-content-md-end">
            {data?.footer?.socialmedialinks.map((data, i) => (
              <a
                href={data.link}
                className="image_wrapper"
                key={i}>
                <img
                  src={`/public/${data.image}`}
                  alt="socialMediaLinks"
                />
              </a>
            ))}
          </div>
        </div>
        <hr />
        <div className="row mt-3 pt-0">
          <div className="col text-center">
            <small className="mb-0">
              <LiveEdithTextHighLighter
                section="footer"
                type="description">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.footer?.description,
                  }}></div>
              </LiveEdithTextHighLighter>
            </small>
          </div>
        </div>
      </div>
    </footer>
  );
}
