import React from 'react'
import LiveEdithTextHighLighter from '../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component'
import LiveEditImageHighLighter from '../../../../liveEditHighlighterstyles/liveEditImageHighLighter.component'
import { isImage, isVideo } from '../../../../utils/Helper_functions/VideoAndImage'
import { Section2Container } from './section2.style'

export default function Section2({ data }) {
  return (
    <Section2Container
      id="sec2">
      <div className='Left_section'>
        <LiveEditImageHighLighter
          section="sec2"
          type="img ">
          {isImage(data?.img) ? (
            <img
              src={`/public/${data?.img}`}
              alt="HomeImage"
              loading="lazy"
              className="animatedText2 ImageSection"
            />
          ) : isVideo(data?.img) ? (
            <video
            src={`/public/${data?.img}`}
            className="ImageSection"
            autoPlay
            muted={false} 
            loop
            style={{ width: '100%', objectFit: 'cover' }}
            onClick={(e) => {
              const videoElement = e.target as HTMLVideoElement; 
              if (videoElement.muted) {
                videoElement.muted = false; 
              } else {
                videoElement.muted = true; 
              }
            }}
          />          ) : (
            <iframe
              className=""
              src={`https://www.youtube.com/embed/${data?.img}?autoplay=0&mute=0&loop=1&playlist=${data?.img}&controls=1&rel=0&modestbranding=1&showinfo=0&iv_load_policy=3`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                width: '100%', objectFit: 'cover',
                border: 'none',
                aspectRatio: '1107 / 749'
              }}></iframe>
          )}
        </LiveEditImageHighLighter>
      </div>

      <div className="Right_section slideInLeft">
        <h2 className='heading' style={{ color: 'white', fontFamily: 'Syncopate' }}>
          <LiveEdithTextHighLighter
            section="sec2"
            type="text">
            {data?.text}
          </LiveEdithTextHighLighter>
        </h2>
        <p className='sub_heading' style={{ color: 'white', fontFamily: 'Garamond' }}>
          <LiveEdithTextHighLighter
            section="sec2"
            type="description">
            {data?.description}
          </LiveEdithTextHighLighter>
        </p>
        <p className='sub_heading' style={{ color: 'white', fontFamily: 'Garamond' }}>
          <LiveEdithTextHighLighter
            section="sec2"
            type="description2">
            {data?.description2}
          </LiveEdithTextHighLighter>
        </p>
      </div>
    </Section2Container>
  )
}
