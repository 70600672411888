/** @format */

import { HeroContainer } from './HeroSection.style';

import LiveEdithTextHighLighter from '../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import {
  isImage,
  isVideo,
} from '../../../../utils/Helper_functions/VideoAndImage';
export default function HeroSection({ data }) {
  return (
    <HeroContainer id="sec1">
      <div className="HeroImage">
        {/* <LiveEditImageHighLighter
          section="sec1"
          type="image"> */}
        {isImage(data?.image) ? (
          <img
            src={`/public/${data?.image}`}
            alt="HomeImage"
            loading="lazy"
            className="backgroundImage"
          />
        ) : isVideo(data?.image) ? (
          <video
            src={`/public/${data?.image}`}
            className="backgroundImage"
            autoPlay
            loop
            muted
          />
        ) : (
          <iframe
            className="backgroundImage"
            src={`https://www.youtube.com/embed/${data?.image}?autoplay=1&mute=1&loop=1&playlist=${data?.image}&controls=0&rel=0&modestbranding=1&showinfo=0&iv_load_policy=3`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              width: '100%', objectFit: 'cover',
              border: 'none'
            }}></iframe>
        )}
        {/* </LiveEditImageHighLighter> */}
      </div>
      <div className="hero_information">
        <LiveEdithTextHighLighter
          section="sec1"
          type="text1">
          <div
            dangerouslySetInnerHTML={{ __html: data.text1 }}
            className="sub_header"
            style={{
              fontFamily: 'Montserrat',
              fontSize: '30px',
              fontWeight: 350,
              lineHeight: '1.3181rem',
              textAlign: 'center',
              color: 'black',
              padding: '0 0 15px',
            }}></div>
        </LiveEdithTextHighLighter>

        <LiveEdithTextHighLighter
          section="sec1"
          type="text2">
          <div
            className="header"
            dangerouslySetInnerHTML={{ __html: data.text2 }}></div>
        </LiveEdithTextHighLighter>

        <LiveEdithTextHighLighter
          section="sec1"
          type="description">
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: data.description }}></div>
        </LiveEdithTextHighLighter>
      </div>
    </HeroContainer>
  );
}
