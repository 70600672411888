import React, { useCallback, useEffect, useState } from "react";
import { RGBAprops } from "../../../../WebsiteDesigner/ColorPicker";
import Cookies from "js-cookie";
import axios from "axios";
type ImageFit = "contain" | "cover" | "horizontal-cover" | "vertical-cover";
export default function useProfileCropper({
  aspect,
  blendcolor,
  handlImageInputChange,
}) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [ObjectFit, setObjectFit] = useState<ImageFit>("contain");
  const [loader, setLoader] = useState<boolean>(false);
  const [blend, setBlend] = useState<boolean>(false);
  const [color, setColor] = useState<RGBAprops>({
    r: null,
    g: null,
    b: null,
    a: null,
  });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<{
    x: number;
    y: number;
    width: number;
    height: number;
  } | null>(null);
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setError(selectedFile.size >= 100 * 1000);
      const reader = new FileReader();
      reader.addEventListener("load", () => setImgSrc(reader.result as string));
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
      e.target.value = "";
      setShowModal(true);
    }
  };

  const onCropChange = useCallback(
    (crop: { x: number; y: number; width: number; height: number }) => {
      setCrop(crop);
    },
    []
  );
  const onZoomChange = useCallback((zoom: number) => {
    setZoom(zoom);
  }, []);
  const onCropComplete = useCallback(
    (
      croppedArea: { x: number; y: number; width: number; height: number },
      croppedAreaPixels: {
        x: number;
        y: number;
        width: number;
        height: number;
      }
    ) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );
  const closeModal = () => {
    setShowModal(false);
  };
  const handleObjectFit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setObjectFit(e.target.value as ImageFit);
  };
  async function getCroppedImg(
    imageSrc: string,
    crop: { x: number; y: number; width: number; height: number },
    zoom: number,
    color: RGBAprops
  ): Promise<Blob> {
    const image = new Image();
    image.src = imageSrc;
    await new Promise<void>((resolve) => {
      image.onload = () => {
        resolve();
      };
    });
    const tempCanvas = document.createElement("canvas");
    const tempCtx = tempCanvas.getContext("2d");
    tempCanvas.width = image.naturalWidth;
    tempCanvas.height = image.naturalHeight;
    tempCtx?.drawImage(image, 0, 0);
    const { data } = tempCtx!.getImageData(crop.x, crop.y, 1, 1);
    let backgroundColor: string;
    if (blend) {
      backgroundColor = blendcolor;
    } else if (
      color.r === null &&
      color.g === null &&
      color.b === null &&
      color.a === null
    ) {
      backgroundColor = `rgba(${data[0]}, ${data[1]}, ${data[2]}, ${
        data[3] / 255
      })`;
    } else {
      backgroundColor = `rgba(${color.r},${color.g},${color.b},${color.a})`;
    }
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    ctx!.fillStyle = backgroundColor;
    ctx!.fillRect(0, 0, canvas.width, canvas.height);
    const cropX = crop.x;
    const cropY = crop.y;
    const cropWidth = crop.width;
    const cropHeight = crop.height;
    ctx?.drawImage(
      image,
      cropX * scaleX,
      cropY * scaleY,
      cropWidth * scaleX,
      cropHeight * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise<Blob>((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          throw new Error("Canvas is empty");
        }
        resolve(blob);
      }, "image/jpeg");
    }) as Promise<Blob>;
  }
  const handleUpload = async () => {
    const token = Cookies.get("token");
    if (!file || !croppedAreaPixels) {
      console.error("No file to upload or crop not complete.");
      return;
    }
    try {
      setLoader(false);
      const croppedImage = await getCroppedImg(
        imgSrc!,
        croppedAreaPixels,
        zoom,
        color
      );
      const formData = new FormData();
      formData.append("v", "d");
      formData.append("image", croppedImage, "cropped.jpg");
      fetch("/api/upload", {
        method: "POST",
        body: formData,
        headers: {
          "x-access-token": token || "",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Network response was not ok");
        })
        .then((data2) => {
          //   if (cardSec) {
          //     data2.file && handleInputChange(cardSec, i, k, data2.file);
          //     sectActiveSectionImage(cardSec, k + i, "active", false);
          //   } else {
          //     data2.file && handleInputChange(i, k, data2.file);
          //     sectActiveSectionImage(i, k, "active", false);
          //   }
          if (data2.file) {
            handlImageInputChange(data2.file);
          }

          closeModal();
          setColor({ r: null, g: null, b: null, a: null });
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    } catch (e) {
      console.error("Error in cropping image:", e);
    }
  };
  const uploadVideo = async (file: File) => {
    const token = Cookies.get("token");
    if (!file) {
      console.error("No file to upload.");
      return;
    }
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append("video", file);
      const response = await axios.post("/api/upload-video", formData, {
        headers: {
          "x-access-token": token,
          "Content-Type": "multipart/form-data",
        },
      });
      const data = await response.data;
      //   if (data) {
      //     if (cardSec) {
      //       handleInputChange(cardSec, i, k, data.file);
      //       sectActiveSectionImage(cardSec, k + i, "active", false);
      //     } else {
      //       handleInputChange(i, k, data.file);
      //       sectActiveSectionImage(i, k, "active", false);
      //     }
      //   }
    } catch {
      console.error("Error uploading image:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (imgSrc) {
      const image = new Image();
      image.src = imgSrc;
      image.onload = () => {
        const { width, height } = image;
        const cropAspect = aspect;
        const imageAspect = width / height;
        let newZoom = 1;
        if (cropAspect > imageAspect) {
          newZoom = width / height / aspect;
        } else {
          newZoom = (height / width) * aspect;
        }
        setZoom(newZoom);
        // Sample the initial background color from the image
        const tempCanvas = document.createElement("canvas");
        const tempCtx = tempCanvas.getContext("2d");
        tempCanvas.width = width;
        tempCanvas.height = height;
        tempCtx?.drawImage(image, 0, 0);
        const { data } = tempCtx!.getImageData(0, 0, 1, 1);
        setColor({ r: data[0], g: data[1], b: data[2], a: data[3] / 255 });
      };
    }
  }, [imgSrc, aspect]);
  return {
    color,
    ObjectFit,
    imgSrc,
    crop,
    zoom,
    showModal,
    loader,
    blend,
    onCropChange,
    onZoomChange,
    onCropComplete,
    handleUpload,
    onSelectFile,
    closeModal,
    handleObjectFit,
    setColor,
    setBlend,
  };
}
