import React, { useEffect, useState } from "react";
import {
  MainContainer,
  InputContainer,
  InputImageContainer,
} from "./pofile.style";
import axios from "axios";
import Cookies from "js-cookie";
import ProfileUploadImage from "./ProfileUploadImage/ProfileUploadImage";
type ProfileProps = {
  _id: string;
  name: string;
  phone: string;
  description: string;
  email: string;
  image: string;
};
export const ProfileDashboard = () => {
  const token = Cookies.get("token");

  const [profile, setProfile] = useState<ProfileProps>({
    _id: "",
    name: "",
    phone: "",
    description: "",
    email: "",
    image: "",
  });
  useEffect(() => {
    const fetchProfile = async (): Promise<void> => {
      try {
        const profileData = await axios.get("/api/get-agent-profile", {
          headers: {
            "x-access-token": token,
          },
        });
        setProfile(profileData.data.data);
      } catch (e) {
        throw new Error(e);
      }
    };
    fetchProfile();
  }, []);
  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    console.log(e.target.name, e.target.value);
    setProfile((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handlImageInputChange = (data: string) => {
    console.log(data);
    setProfile((prev) => ({ ...prev, image: data }));
  };
  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    try {
      const UpdatedProfile = await axios.post(
        `/admin/update-agent/${profile._id}`,
        {
          name: profile.name,
          email: profile.email,
          phone: profile.phone,
          description: profile.description,
          image: profile.image,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      setProfile(UpdatedProfile.data.data);
    } catch (e) {
      throw new Error(e);
    }
  };
  return (
    <MainContainer>
      <h3>Profile</h3>
      <form className="Profile" onSubmit={handleSubmit}>
        <InputImageContainer>
          <label> Profile Image</label>
          <img className="profile_image" src={`/public/${profile.image}`} alt={"Agent_Profile_Image"} />
          <ProfileUploadImage
            aspect={3 / 2}
            handlImageInputChange={handlImageInputChange}
          />
        </InputImageContainer>
        <InputContainer>
          <label> Name</label>
          <input
            name="name"
            type="text"
            onChange={handleInputChange}
            value={profile.name}
          />
        </InputContainer>
        <InputContainer>
          <label> Email</label>
          <input
            name="email"
            type="email"
            onChange={handleInputChange}
            value={profile.email}
          />
        </InputContainer>
        <InputContainer>
          <label>Phone</label>
          <input
            name="phone"
            type="text"
            onChange={handleInputChange}
            value={profile.phone}
          />
        </InputContainer>
        <InputContainer>
          <label>Description</label>
          <textarea
            name="description"
            onChange={handleInputChange}
            value={profile.description}
          />
        </InputContainer>
        <button type="submit">Update</button>
      </form>
    </MainContainer>
  );
};
