/** @format */

import { MlsDetailContainer } from './mlsDetail.style';
// import './CrmDetailCard.css';
import { useDashboardMlsContext } from '../../../../../../context/DashboardMlsListing.context';
import ConvertToCanadianNumber from '../../../../../../utils/New_construction_details/ConvertNumberToDollar.util';
import useListing from '../useListing';
export default function MlsDetailCard({ Data }) {
  const { deleteMlsListing } = useListing();
  const { setFormVisualState, setFormType, setMlsNumber } =
    useDashboardMlsContext();

  const handleDelete = () => {
    deleteMlsListing(Data._id);
  };
  const handlePreviewOpen = () => {
    setFormVisualState(true);
    setFormType('preview');
    setMlsNumber(Data.mlsNumber);
  };

  return (
    <>
      <MlsDetailContainer>
        <div className="detail_left_section">
          <div className="property_image">
            <img
              src={Data.image}
              alt="mls_image"
            />
          </div>
          <div className="property_details">
            <div className="upper_section">
              <h1 className="address">{Data.address}</h1>
              <p className="price">${Data.price}</p>
            </div>
            <div className="bottom_section">
              <div className="bed">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none">
                  <path
                    d="M15.832 6.33329H12.082C10.4712 6.33329 9.16536 7.63913 9.16536 9.24996C9.16536 10.8608 7.85953 12.1666 6.2487 12.1666H5.4987C3.84184 12.1666 2.4987 10.8235 2.4987 9.16663V5.49996C2.4987 5.03972 2.1256 4.66663 1.66536 4.66663C1.20513 4.66663 0.832031 5.03972 0.832031 5.49996V16.3333C0.832031 16.7935 1.20513 17.1666 1.66536 17.1666C2.1256 17.1666 2.4987 16.7935 2.4987 16.3333V15.9166C2.4987 15.2263 3.05834 14.6666 3.7487 14.6666H16.2487C16.9391 14.6666 17.4987 15.2263 17.4987 15.9166V16.3333C17.4987 16.7935 17.8718 17.1666 18.332 17.1666C18.7923 17.1666 19.1654 16.7935 19.1654 16.3333V9.66663C19.1654 8.78257 18.8142 7.93472 18.1891 7.3096C17.5639 6.68448 16.7161 6.33329 15.832 6.33329ZM5.83203 11.3333C6.49507 11.3333 7.13096 11.0699 7.5998 10.6011C8.06864 10.1322 8.33203 9.49633 8.33203 8.83329C8.33203 8.17025 8.06864 7.53437 7.5998 7.06553C7.13096 6.59668 6.49507 6.33329 5.83203 6.33329C5.16899 6.33329 4.5331 6.59668 4.06426 7.06553C3.59542 7.53437 3.33203 8.17025 3.33203 8.83329C3.33203 9.49633 3.59542 10.1322 4.06426 10.6011C4.5331 11.0699 5.16899 11.3333 5.83203 11.3333Z"
                    fill="#999999"
                  />
                </svg>
                <p>{Data.beedroom}</p>
              </div>
              <div className="bath">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none">
                  <path
                    d="M18.25 12.1666C18.0199 12.1666 17.8333 12.3532 17.8333 12.5833V13C17.8333 14.3118 17.2276 15.4821 16.2775 16.245C15.9191 16.5328 15.7036 16.9881 15.8155 17.434L15.9145 17.8286C16.0426 18.3388 15.6568 18.8333 15.1309 18.8333C14.7601 18.8333 14.4369 18.581 14.347 18.2213L14.2727 17.9241C14.1614 17.4789 13.7614 17.1666 13.3026 17.1666H7.36411C6.90524 17.1666 6.50526 17.4789 6.39397 17.9241L6.31967 18.2213C6.22975 18.581 5.90657 18.8333 5.53581 18.8333C5.00986 18.8333 4.62411 18.3388 4.75213 17.8286L4.851 17.4347C4.96298 16.9885 4.74707 16.5329 4.38827 16.245C4.00692 15.939 3.68094 15.5668 3.42699 15.1442C3.0379 14.4967 2.83265 13.7554 2.83333 13V12.5833C2.83333 12.3532 2.64679 12.1666 2.41667 12.1666C2.18655 12.1666 2 11.9801 2 11.75V11.3333C2 10.8731 2.3731 10.5 2.83333 10.5H16C16.5523 10.5 17 10.0522 17 9.49996V4.66663C17 4.44561 16.9122 4.23365 16.7559 4.07737C16.5996 3.92109 16.3877 3.83329 16.1667 3.83329C15.982 3.83329 15.8171 3.88892 15.6819 3.98349C15.3669 4.20383 15.5352 4.64122 15.7508 4.95952C15.8359 5.08528 15.9091 5.21931 15.9692 5.35989C16.1864 5.86767 15.719 6.33329 15.1667 6.33329H12.1667C11.6144 6.33329 11.1489 5.86841 11.364 5.35973C11.4883 5.0658 11.6689 4.79556 11.8989 4.56553C12.3677 4.09668 13.0036 3.83329 13.6667 3.83329C13.7516 3.83329 13.8265 3.77941 13.8594 3.70111C14.2358 2.80346 15.1254 2.16663 16.1667 2.16663C16.8297 2.16663 17.4656 2.43002 17.9344 2.89886C18.4033 3.3677 18.6667 4.00358 18.6667 4.66663V11.75C18.6667 11.9801 18.4801 12.1666 18.25 12.1666Z"
                    fill="#999999"
                  />
                </svg>
                <p>{Data.bathroom}</p>
              </div>
              <div className="garage">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none">
                  <path
                    d="M16.4987 16.3334C16.4987 16.7937 16.1256 17.1667 15.6654 17.1667C15.2051 17.1667 14.832 16.7937 14.832 16.3334V10.6667C14.832 10.1145 14.3843 9.66675 13.832 9.66675H7.4987C6.94641 9.66675 6.4987 10.1145 6.4987 10.6667V16.3334C6.4987 16.7937 6.1256 17.1667 5.66536 17.1667C5.20513 17.1667 4.83203 16.7937 4.83203 16.3334V8.5804C4.83203 8.22155 5.02432 7.8902 5.33589 7.71216L10.1692 4.95026C10.4767 4.77458 10.8541 4.77458 11.1615 4.95026L15.9948 7.71216C16.3064 7.8902 16.4987 8.22155 16.4987 8.5804V16.3334ZM7.33203 11.3334C7.33203 10.8732 7.70513 10.5001 8.16536 10.5001H13.1654C13.6256 10.5001 13.9987 10.8732 13.9987 11.3334C13.9987 11.7937 13.6256 12.1667 13.1654 12.1667H8.16536C7.70513 12.1667 7.33203 11.7937 7.33203 11.3334ZM7.33203 13.8334C7.33203 13.3732 7.70513 13.0001 8.16536 13.0001H13.1654C13.6256 13.0001 13.9987 13.3732 13.9987 13.8334C13.9987 14.2937 13.6256 14.6667 13.1654 14.6667H8.16536C7.70513 14.6667 7.33203 14.2937 7.33203 13.8334ZM13.1654 15.5001C13.6256 15.5001 13.9987 15.8732 13.9987 16.3334C13.9987 16.7937 13.6256 17.1667 13.1654 17.1667H8.16536C7.70513 17.1667 7.33203 16.7937 7.33203 16.3334C7.33203 15.8732 7.70513 15.5001 8.16536 15.5001H13.1654Z"
                    fill="#999999"
                  />
                </svg>
                <p>{Data.garage}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="detail_right_section">
          <div
            onClick={handlePreviewOpen}
            className="mls_preview">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none">
              <path
                d="M9 11.75C10.2426 11.75 11.25 10.7426 11.25 9.5C11.25 8.25736 10.2426 7.25 9 7.25C7.75736 7.25 6.75 8.25736 6.75 9.5C6.75 10.7426 7.75736 11.75 9 11.75Z"
                fill="#474747"
              />
              <path
                d="M17.4035 9.30875C16.7419 7.59743 15.5934 6.11747 14.0998 5.0518C12.6063 3.98612 10.8332 3.38145 8.99976 3.3125C7.1663 3.38145 5.39323 3.98612 3.89969 5.0518C2.40614 6.11747 1.2576 7.59743 0.596011 9.30875C0.55133 9.43234 0.55133 9.56766 0.596011 9.69125C1.2576 11.4026 2.40614 12.8825 3.89969 13.9482C5.39323 15.0139 7.1663 15.6186 8.99976 15.6875C10.8332 15.6186 12.6063 15.0139 14.0998 13.9482C15.5934 12.8825 16.7419 11.4026 17.4035 9.69125C17.4482 9.56766 17.4482 9.43234 17.4035 9.30875ZM8.99976 13.1562C8.27662 13.1562 7.56972 12.9418 6.96846 12.5401C6.36719 12.1383 5.89856 11.5673 5.62183 10.8992C5.34509 10.2311 5.27269 9.49594 5.41376 8.7867C5.55484 8.07746 5.90307 7.42598 6.4144 6.91464C6.92574 6.40331 7.57722 6.05508 8.28646 5.914C8.9957 5.77293 9.73085 5.84533 10.3989 6.12207C11.067 6.3988 11.6381 6.86743 12.0398 7.4687C12.4416 8.06996 12.656 8.77686 12.656 9.5C12.6545 10.4692 12.2688 11.3984 11.5835 12.0837C10.8981 12.7691 9.969 13.1548 8.99976 13.1562Z"
                fill="#474747"
              />
            </svg>
          </div>
          <div className="mls_edit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none">
              <path
                d="M2.25 13.4374V16.2499H5.0625L13.3575 7.95488L10.545 5.14238L2.25 13.4374ZM15.5325 5.77988C15.602 5.71049 15.6572 5.62808 15.6948 5.53735C15.7325 5.44662 15.7518 5.34936 15.7518 5.25113C15.7518 5.1529 15.7325 5.05564 15.6948 4.96491C15.6572 4.87418 15.602 4.79177 15.5325 4.72238L13.7775 2.96738C13.7081 2.89785 13.6257 2.84269 13.535 2.80506C13.4442 2.76742 13.347 2.74805 13.2488 2.74805C13.1505 2.74805 13.0533 2.76742 12.9625 2.80506C12.8718 2.84269 12.7894 2.89785 12.72 2.96738L11.3475 4.33988L14.16 7.15238L15.5325 5.77988Z"
                fill="#474747"
              />
            </svg>
          </div>
          <div
            onClick={handleDelete}
            className="mls_delete">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none">
              <path
                d="M4.5 14.75C4.5 15.575 5.175 16.25 6 16.25H12C12.825 16.25 13.5 15.575 13.5 14.75V5.75H4.5V14.75ZM6.8775 9.9425C6.73727 9.80227 6.65848 9.61207 6.65848 9.41375C6.65848 9.21543 6.73727 9.02523 6.8775 8.885C7.01773 8.74477 7.20793 8.66598 7.40625 8.66598C7.60457 8.66598 7.79477 8.74477 7.935 8.885L9 9.9425L10.0575 8.885C10.1977 8.74477 10.3879 8.66598 10.5862 8.66598C10.7846 8.66598 10.9748 8.74477 11.115 8.885C11.2552 9.02523 11.334 9.21543 11.334 9.41375C11.334 9.61207 11.2552 9.80227 11.115 9.9425L10.0575 11L11.115 12.0575C11.1844 12.1269 11.2395 12.2094 11.2771 12.3001C11.3147 12.3908 11.334 12.4881 11.334 12.5863C11.334 12.6844 11.3147 12.7817 11.2771 12.8724C11.2395 12.9631 11.1844 13.0456 11.115 13.115C11.0456 13.1844 10.9631 13.2395 10.8724 13.2771C10.7817 13.3147 10.6844 13.334 10.5862 13.334C10.4881 13.334 10.3908 13.3147 10.3001 13.2771C10.2094 13.2395 10.1269 13.1844 10.0575 13.115L9 12.0575L7.9425 13.115C7.87306 13.1844 7.79063 13.2395 7.69991 13.2771C7.60918 13.3147 7.51195 13.334 7.41375 13.334C7.31555 13.334 7.21832 13.3147 7.12759 13.2771C7.03687 13.2395 6.95444 13.1844 6.885 13.115C6.81556 13.0456 6.76048 12.9631 6.7229 12.8724C6.68533 12.7817 6.66598 12.6844 6.66598 12.5863C6.66598 12.4881 6.68533 12.3908 6.7229 12.3001C6.76048 12.2094 6.81556 12.1269 6.885 12.0575L7.9425 11L6.8775 9.9425ZM13.5 3.5H11.625L11.0925 2.9675C10.9575 2.8325 10.7625 2.75 10.5675 2.75H7.4325C7.2375 2.75 7.0425 2.8325 6.9075 2.9675L6.375 3.5H4.5C4.0875 3.5 3.75 3.8375 3.75 4.25C3.75 4.6625 4.0875 5 4.5 5H13.5C13.9125 5 14.25 4.6625 14.25 4.25C14.25 3.8375 13.9125 3.5 13.5 3.5Z"
                fill="#474747"
              />
            </svg>
          </div>
        </div>
      </MlsDetailContainer>
    </>
  );
}
