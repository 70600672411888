/** @format */

import Cookies from "js-cookie";
import { Link, Outlet, useLocation } from "react-router-dom";
import "./DashBoard.css";
import Header from "./Header";
import RightArrow from "./Images/rightArrow.svg";

export default function DashBoard() {
  const location = useLocation();
  const currentPath = location.pathname;

  const token = Cookies.get("token");

  if (!token) {
    window.location.href = "/register";
    return null;
  }

  return (
    <div className="dash_board">
      <Header />
      <div className="DashBoard_Container d-flex gap-2">
        <aside className="sidebar">
          <div className="Links">
            <Link
              to={"blogs"}
              className={`categoryelement  d-flex justify-content-between align-items-center ${
                currentPath.includes("blogs") && "categoryelement_active"
              }`}
            >
              <div className="wrapper d-flex align-items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    d="M4.125 3.125C3.57272 3.125 3.125 3.57272 3.125 4.125V20.875C3.125 21.4273 3.57272 21.875 4.125 21.875H20.875C21.4273 21.875 21.875 21.4273 21.875 20.875V4.125C21.875 3.57272 21.4273 3.125 20.875 3.125H4.125ZM18.75 18.2292C18.75 18.5168 18.5168 18.75 18.2292 18.75H6.77083C6.48318 18.75 6.25 18.5168 6.25 18.2292C6.25 17.9415 6.48318 17.7083 6.77083 17.7083H18.2292C18.5168 17.7083 18.75 17.9415 18.75 18.2292ZM18.75 16.1458C18.75 16.4335 18.5168 16.6667 18.2292 16.6667H6.77083C6.48318 16.6667 6.25 16.4335 6.25 16.1458C6.25 15.8582 6.48318 15.625 6.77083 15.625H18.2292C18.5168 15.625 18.75 15.8582 18.75 16.1458ZM18.75 11.5C18.75 12.0523 18.3023 12.5 17.75 12.5H7.25C6.69771 12.5 6.25 12.0523 6.25 11.5V7.25C6.25 6.69772 6.69772 6.25 7.25 6.25H17.75C18.3023 6.25 18.75 6.69772 18.75 7.25V11.5Z"
                    fill="#FAFAFC"
                  />
                </svg>
                <p>Blogs</p>
              </div>
              <img src={RightArrow} alt="RightArrow" />
            </Link>
            <div className="dashboardbar"></div>
            <Link
              to={"pricing-blilling"}
              className={`categoryelement  d-flex justify-content-between align-items-center ${
                currentPath.includes("pricing-blilling") &&
                "categoryelement_active"
              }`}
            >
              <div className="wrapper d-flex align-items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    d="M22.3906 2.61874C22.1963 2.42217 21.9648 2.26623 21.7096 2.16C21.4544 2.05378 21.1806 1.99939 20.9042 2.00001H15.1419C14.7639 2.00071 14.4015 2.15044 14.1332 2.41671L2.61382 13.9336C2.22075 14.3275 2 14.8612 2 15.4176C2 15.9741 2.22075 16.5078 2.61382 16.9017L8.09812 22.3859C8.49209 22.7791 9.02599 23 9.58263 23C10.1393 23 10.6732 22.7791 11.0671 22.3859L22.5818 10.8737C22.8486 10.6058 22.9989 10.2435 23 9.86544V4.09995C23.0017 3.82499 22.9487 3.55243 22.8441 3.29814C22.7394 3.04385 22.5853 2.8129 22.3906 2.61874ZM18.5 7.99986C18.2034 7.99986 17.9133 7.91189 17.6667 7.74707C17.42 7.58225 17.2277 7.34799 17.1142 7.07391C17.0007 6.79982 16.971 6.49823 17.0289 6.20727C17.0867 5.9163 17.2296 5.64903 17.4394 5.43926C17.6491 5.22949 17.9164 5.08663 18.2074 5.02875C18.4984 4.97088 18.8 5.00058 19.074 5.11411C19.3481 5.22764 19.5824 5.41989 19.7472 5.66656C19.912 5.91323 20 6.20323 20 6.49989C20 6.89771 19.842 7.27923 19.5607 7.56053C19.2794 7.84183 18.8978 7.99986 18.5 7.99986Z"
                    fill="#FAFAFC"
                  />
                </svg>
                <p>Pricing & Billing</p>
              </div>
              <img src={RightArrow} alt="RightArrow" />
            </Link>
            <div className="dashboardbar"></div>
            <Link
              to={"buyers-lead"}
              className={`categoryelement  d-flex justify-content-between align-items-center ${
                currentPath.includes("buyers-lead") && "categoryelement_active"
              }`}
            >
              <div className="wrapper d-flex align-items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    d="M19.7917 3.125H5.20833C4.0625 3.125 3.125 4.0625 3.125 5.20833V19.7917C3.125 20.9375 4.0625 21.875 5.20833 21.875H19.7917C20.9375 21.875 21.875 20.9375 21.875 19.7917V5.20833C21.875 4.0625 20.9375 3.125 19.7917 3.125ZM7.29167 7.29167H9.375V9.375H7.29167V7.29167ZM7.29167 11.4583H9.375V13.5417H7.29167V11.4583ZM7.29167 15.625H9.375V17.7083H7.29167V15.625ZM17.7083 17.7083H11.4583V15.625H17.7083V17.7083ZM17.7083 13.5417H11.4583V11.4583H17.7083V13.5417ZM17.7083 9.375H11.4583V7.29167H17.7083V9.375Z"
                    fill="#FAFAFC"
                  />
                </svg>
                <p>Buyer Leads</p>
              </div>
              <img src={RightArrow} alt="RightArrow" />
            </Link>
            <div className="dashboardbar"></div>
            <Link
              to={"seller-lead"}
              className={`categoryelement  d-flex justify-content-between align-items-center ${
                currentPath.includes("seller-lead") && "categoryelement_active"
              }`}
            >
              <div className="wrapper d-flex align-items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    d="M19.7917 3.125H5.20833C4.0625 3.125 3.125 4.0625 3.125 5.20833V19.7917C3.125 20.9375 4.0625 21.875 5.20833 21.875H19.7917C20.9375 21.875 21.875 20.9375 21.875 19.7917V5.20833C21.875 4.0625 20.9375 3.125 19.7917 3.125ZM7.29167 7.29167H9.375V9.375H7.29167V7.29167ZM7.29167 11.4583H9.375V13.5417H7.29167V11.4583ZM7.29167 15.625H9.375V17.7083H7.29167V15.625ZM17.7083 17.7083H11.4583V15.625H17.7083V17.7083ZM17.7083 13.5417H11.4583V11.4583H17.7083V13.5417ZM17.7083 9.375H11.4583V7.29167H17.7083V9.375Z"
                    fill="#FAFAFC"
                  />
                </svg>
                <p>Seller Leads</p>
              </div>
              <img src={RightArrow} alt="RightArrow" />
            </Link>
            <div className="dashboardbar"></div>
            {/* <Link
              to={'crmintigration'}
              className={`categoryelement  d-flex justify-content-between align-items-center ${currentPath.includes('crmintigration') &&
                'categoryelement_active'
                }`}>
              <div className="wrapper d-flex align-items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none">
                  <path
                    d="M21.3543 11.4583H19.7918V7.29163C19.7918 6.73909 19.5723 6.20919 19.1816 5.81849C18.7909 5.42779 18.261 5.20829 17.7085 5.20829H13.5418V3.64579C13.5418 2.95512 13.2675 2.29274 12.7791 1.80437C12.2907 1.31599 11.6283 1.04163 10.9377 1.04163C10.247 1.04163 9.58461 1.31599 9.09624 1.80437C8.60786 2.29274 8.3335 2.95512 8.3335 3.64579V5.20829H4.16683C3.6143 5.20829 3.08439 5.42779 2.69369 5.81849C2.30299 6.20919 2.0835 6.73909 2.0835 7.29163V11.25H3.646C5.2085 11.25 6.4585 12.5 6.4585 14.0625C6.4585 15.625 5.2085 16.875 3.646 16.875H2.0835V20.8333C2.0835 21.3858 2.30299 21.9157 2.69369 22.3064C3.08439 22.6971 3.6143 22.9166 4.16683 22.9166H8.12516V21.3541C8.12516 19.7916 9.37516 18.5416 10.9377 18.5416C12.5002 18.5416 13.7502 19.7916 13.7502 21.3541V22.9166H17.7085C18.261 22.9166 18.7909 22.6971 19.1816 22.3064C19.5723 21.9157 19.7918 21.3858 19.7918 20.8333V16.6666H21.3543C22.045 16.6666 22.7074 16.3923 23.1958 15.9039C23.6841 15.4155 23.9585 14.7531 23.9585 14.0625C23.9585 13.3718 23.6841 12.7094 23.1958 12.221C22.7074 11.7327 22.045 11.4583 21.3543 11.4583Z"
                    fill="#FAFAFC"
                  />
                </svg>
                <p>CRM Integration</p>
              </div>
              <img
                src={RightArrow}
                alt="RightArrow"
              />
            </Link>
            <div className="dashboardbar"></div> */}
            <Link
              to={"seomanager"}
              className={`categoryelement  d-flex justify-content-between align-items-center ${
                currentPath.includes("seomanager") && "categoryelement_active"
              }`}
            >
              <div className="wrapper d-flex align-items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="21"
                  viewBox="0 0 24 21"
                  fill="none"
                >
                  <path
                    d="M7.81283 8.45833C5.20866 8.45833 3.12533 10.5417 3.12533 13.1458C3.12533 13.9195 3.31085 14.6561 3.64432 15.2993C3.75199 15.5069 3.72692 15.7638 3.56205 15.9297L0.987011 18.5214C0.792827 18.7169 0.793338 19.0326 0.988153 19.2274L1.73295 19.9722C1.9271 20.1664 2.24148 20.1676 2.43719 19.975L5.05286 17.4013C5.21757 17.2392 5.47067 17.2137 5.67638 17.319C6.31773 17.6472 7.04667 17.8333 7.81283 17.8333C10.417 17.8333 12.5003 15.75 12.5003 13.1458C12.5003 10.5417 10.417 8.45833 7.81283 8.45833ZM7.81283 15.75C7.12216 15.75 6.45978 15.4756 5.9714 14.9873C5.48303 14.4989 5.20866 13.8365 5.20866 13.1458C5.20866 12.4552 5.48303 11.7928 5.9714 11.3044C6.45978 10.816 7.12216 10.5417 7.81283 10.5417C8.50349 10.5417 9.16587 10.816 9.65425 11.3044C10.1426 11.7928 10.417 12.4552 10.417 13.1458C10.417 13.8365 10.1426 14.4989 9.65425 14.9873C9.16587 15.4756 8.50349 15.75 7.81283 15.75ZM23.9587 2.20833V16.7917C23.9587 17.9479 23.0316 18.875 21.8753 18.875H13.1685C12.6647 18.875 12.4193 18.1269 12.7621 17.7578C12.9772 17.5262 13.1766 17.279 13.3587 17.0186C13.4562 16.8791 13.6137 16.7917 13.7839 16.7917H21.3753C21.6515 16.7917 21.8753 16.5678 21.8753 16.2917V6.875C21.8753 6.59886 21.6515 6.375 21.3753 6.375H14.042C13.7658 6.375 13.542 6.15114 13.542 5.875V2.70833C13.542 2.43219 13.3181 2.20833 13.042 2.20833H3.62533C3.34918 2.20833 3.12533 2.43219 3.12533 2.70833V8.05762C3.12533 8.19383 3.06909 8.3235 2.9738 8.42081C1.78115 9.63873 1.04199 11.3059 1.04199 13.1458V2.20833C1.04199 1.0625 1.97949 0.125 3.12533 0.125H21.8753C22.4279 0.125 22.9578 0.344493 23.3485 0.735194C23.7392 1.12589 23.9587 1.6558 23.9587 2.20833Z"
                    fill="#FAFAFC"
                  />
                </svg>
                <p>seo manager</p>
              </div>
              <img src={RightArrow} alt="RightArrow" />
            </Link>
            <div className="dashboardbar"></div>
            <Link
              to={"domain_linking"}
              className={`categoryelement  d-flex justify-content-between align-items-center ${
                currentPath.includes("domain_linking") &&
                "categoryelement_active"
              }`}
            >
              <div className="wrapper d-flex align-items-center gap-2">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.7626 7.19489C10.4347 6.86686 10.0453 6.60664 9.61684 6.42911C9.18833 6.25157 8.72904 6.16019 8.26521 6.16019C7.80138 6.16019 7.34209 6.25157 6.91358 6.42911C6.48507 6.60664 6.09573 6.86686 5.76781 7.19489L2.2001 10.7636C1.53789 11.426 1.16592 12.3243 1.16602 13.2609C1.16611 14.1975 1.53827 15.0957 2.20062 15.7579C2.86297 16.4201 3.76126 16.7921 4.69787 16.792C5.63447 16.7919 6.53268 16.4197 7.19489 15.7574L7.52927 15.4407M7.19489 10.7636C7.52282 11.0917 7.91215 11.3519 8.34066 11.5294C8.76917 11.707 9.22846 11.7983 9.69229 11.7983C10.1561 11.7983 10.6154 11.707 11.0439 11.5294C11.4724 11.3519 11.8618 11.0917 12.1897 10.7636L15.7564 7.19489C16.4187 6.53268 16.7909 5.63447 16.791 4.69787C16.7911 3.76126 16.4191 2.86297 15.7569 2.20062C15.0947 1.53827 14.1965 1.16611 13.2598 1.16602C12.3232 1.16592 11.425 1.53789 10.7626 2.2001L9.69177 3.20114"
                    stroke="#FAFAFC"
                    stroke-width="2.08333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p>Domain Linking</p>
              </div>
              <img src={RightArrow} alt="RightArrow" />
            </Link>
            <div className="dashboardbar"></div>
            <Link
              to={"mls_listing"}
              className={`categoryelement  d-flex justify-content-between align-items-center ${
                currentPath.includes("domain_linking") &&
                "categoryelement_active"
              }`}
            >
              <div className="wrapper d-flex align-items-center gap-2">
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 9.59961V19.2C19 19.4122 18.9166 19.6156 18.7681 19.7657C18.6197 19.9157 18.4183 20 18.2083 20H12.6667C12.4567 20 12.2553 19.9157 12.1069 19.7657C11.9584 19.6156 11.875 19.4122 11.875 19.2V13.9998C11.875 13.8937 11.8333 13.7919 11.7591 13.7169C11.6848 13.6419 11.5841 13.5998 11.4792 13.5998H7.52083C7.41585 13.5998 7.31517 13.6419 7.24094 13.7169C7.1667 13.7919 7.125 13.8937 7.125 13.9998V19.2C7.125 19.4122 7.04159 19.6156 6.89313 19.7657C6.74466 19.9157 6.5433 20 6.33333 20H0.791667C0.581704 20 0.38034 19.9157 0.231874 19.7657C0.0834077 19.6156 0 19.4122 0 19.2V9.59961C0.000194641 9.17533 0.167137 8.76849 0.464114 8.46857L8.38078 0.468276C8.67768 0.168433 9.08025 0 9.5 0C9.91975 0 10.3223 0.168433 10.6192 0.468276L18.5359 8.46857C18.8329 8.76849 18.9998 9.17533 19 9.59961Z"
                    fill="#FAFAFC"
                  />
                </svg>
                <p>Manage Listings</p>
              </div>
              <img src={RightArrow} alt="RightArrow" />
            </Link>
            <div className="dashboardbar"></div>
            <Link
              to={"Exclusive_listing"}
              className={`categoryelement  d-flex justify-content-between align-items-center ${
                currentPath.includes("domain_linking") &&
                "categoryelement_active"
              }`}
            >
              <div className="wrapper d-flex align-items-center gap-2">
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 9.59961V19.2C19 19.4122 18.9166 19.6156 18.7681 19.7657C18.6197 19.9157 18.4183 20 18.2083 20H12.6667C12.4567 20 12.2553 19.9157 12.1069 19.7657C11.9584 19.6156 11.875 19.4122 11.875 19.2V13.9998C11.875 13.8937 11.8333 13.7919 11.7591 13.7169C11.6848 13.6419 11.5841 13.5998 11.4792 13.5998H7.52083C7.41585 13.5998 7.31517 13.6419 7.24094 13.7169C7.1667 13.7919 7.125 13.8937 7.125 13.9998V19.2C7.125 19.4122 7.04159 19.6156 6.89313 19.7657C6.74466 19.9157 6.5433 20 6.33333 20H0.791667C0.581704 20 0.38034 19.9157 0.231874 19.7657C0.0834077 19.6156 0 19.4122 0 19.2V9.59961C0.000194641 9.17533 0.167137 8.76849 0.464114 8.46857L8.38078 0.468276C8.67768 0.168433 9.08025 0 9.5 0C9.91975 0 10.3223 0.168433 10.6192 0.468276L18.5359 8.46857C18.8329 8.76849 18.9998 9.17533 19 9.59961Z"
                    fill="#FAFAFC"
                  />
                </svg>
                <p>Exclusive Listings</p>
              </div>
              <img src={RightArrow} alt="RightArrow" />
            </Link>
            <div className="dashboardbar"></div>
            <Link
              to={"profile"}
              className={`categoryelement  d-flex justify-content-between align-items-center ${
                currentPath.includes("domain_linking") &&
                "categoryelement_active"
              }`}
            >
              <div className="wrapper d-flex align-items-center gap-2">
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 9.59961V19.2C19 19.4122 18.9166 19.6156 18.7681 19.7657C18.6197 19.9157 18.4183 20 18.2083 20H12.6667C12.4567 20 12.2553 19.9157 12.1069 19.7657C11.9584 19.6156 11.875 19.4122 11.875 19.2V13.9998C11.875 13.8937 11.8333 13.7919 11.7591 13.7169C11.6848 13.6419 11.5841 13.5998 11.4792 13.5998H7.52083C7.41585 13.5998 7.31517 13.6419 7.24094 13.7169C7.1667 13.7919 7.125 13.8937 7.125 13.9998V19.2C7.125 19.4122 7.04159 19.6156 6.89313 19.7657C6.74466 19.9157 6.5433 20 6.33333 20H0.791667C0.581704 20 0.38034 19.9157 0.231874 19.7657C0.0834077 19.6156 0 19.4122 0 19.2V9.59961C0.000194641 9.17533 0.167137 8.76849 0.464114 8.46857L8.38078 0.468276C8.67768 0.168433 9.08025 0 9.5 0C9.91975 0 10.3223 0.168433 10.6192 0.468276L18.5359 8.46857C18.8329 8.76849 18.9998 9.17533 19 9.59961Z"
                    fill="#FAFAFC"
                  />
                </svg>
                <p>Profile</p>
              </div>
              <img src={RightArrow} alt="RightArrow" />
            </Link>
          </div>
        </aside>
        <div className="View">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
