/** @format */

import { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

//agentshades mainpages
import PrivateRoute from './PrivateRoute';
import Close from './utils/tours_functions/close';
import { tours_styles } from './utils/tours_functions/tours_style';
//provider
import { TourProvider } from '@reactour/tour';
//import MessageBoxContextProvider from "./context/Message.context";
//loader
import Spinner from './utils/spinner/spinner.component';
import { NextButton } from './utils/tours_functions/nextbutton';
import { PrevButton } from './utils/tours_functions/prevbutton';
//templates
import Template1Route from './routes/template1.route';
import Template2Route from './routes/template2.route';

//dashboard
import DashBoardRoute from './routes/dashboard.route';
import Template3Route from './routes/template3.route';
// import PageNotFound from './pages/Landing/PageNotFound/PageNotFound';
//agentsahdes pages
const Home = lazy(() => import('./pages/Landing/Home/Home'));
const About = lazy(() => import('./pages/Landing/AboutUs/About'));
const Design = lazy(() => import('./pages/Landing/Design/Design'));
const Product = lazy(() => import('./pages/Landing/Product/Product'));
const ContactUs = lazy(() => import('./pages/Landing/ContactUs/ContactUs'));
const Login = lazy(() => import('./pages/Auth/Login'));
const Register = lazy(() => import('./pages/Auth/Register'));
const Pricing = lazy(() => import('./pages/Landing/Pricing/pricing.component'));
const Instruction = lazy(
  () => import('./pages/Landing/instruction/instruction.component'),
);
const MlsInstruction = lazy(
  () => import('./pages/Landing/mlslistting/mls.component'),
);
const VowInstruction = lazy(
  () => import('./pages/Landing/vowinstruction/vowinstruction.component'),
);
const ColorSelector = lazy(
  () => import('./pages/WebsiteDesigner/ColorSelector'),
);
const Designer = lazy(() => import('./pages/WebsiteDesigner/Designer'));
const DomainInstruction = lazy(
  () => import('./pages/Landing/domain_instruction/Domain_instruction'),
);
const ForgotPassword = lazy(
  () => import('./pages/Auth/ForgotPassword/ForgotPassword.auth'),
);
const PageNotFound = lazy(
  () => import('./pages/Landing/PageNotFound/PageNotFound'),
);
function App() {
  return (
    <Suspense
      fallback={
        <div className='spinner_container'>
          {' '}
          <Spinner />
        </div>
      }
    >
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/design' element={<Design />} />
          <Route path='/product' element={<Product />} />
          <Route path='/contactus' element={<ContactUs />} />
          <Route path='/instruction' element={<Instruction />} />
          <Route path='/mlsinstruction' element={<MlsInstruction />} />
          <Route path='/vowinstruction' element={<VowInstruction />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/reset-password' element={<ForgotPassword />} />
          <Route path='/domain_instructions' element={<DomainInstruction />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='designer/:templateId'>
            <Route
              index
              element={
                <PrivateRoute>
                  <TourProvider
                    steps={[]}
                    badgeContent={({ totalSteps, currentStep }) =>
                      `STEP${currentStep + 1}`
                    }
                    styles={tours_styles}
                    onClickMask={(clickProps) => clickProps.setIsOpen(true)}
                    prevButton={({ currentStep, setCurrentStep, steps }) =>
                      PrevButton(currentStep, setCurrentStep, steps)
                    }
                    nextButton={({
                      currentStep,
                      setCurrentStep,
                      steps,
                      setIsOpen,
                    }) =>
                      NextButton(
                        currentStep,
                        setCurrentStep,
                        steps,
                        setIsOpen,
                        true,
                      )
                    }
                    onClickClose={({
                      currentStep,
                      setCurrentStep,
                      steps,
                      setIsOpen,
                    }) => {
                      setCurrentStep(0);
                      setIsOpen(false);
                    }}
                    components={{ Close }}
                  >
                    <Designer />
                  </TourProvider>
                </PrivateRoute>
              }
            />
            <Route
              path='colorselector'
              element={
                <PrivateRoute>
                  <TourProvider
                    steps={[]}
                    badgeContent={({ totalSteps, currentStep }) =>
                      `STEP${currentStep + 1}`
                    }
                    styles={tours_styles}
                    onClickMask={(clickProps) => clickProps.setIsOpen(true)}
                    prevButton={({ currentStep, setCurrentStep, steps }) =>
                      PrevButton(currentStep, setCurrentStep, steps)
                    }
                    nextButton={({
                      currentStep,
                      setCurrentStep,
                      steps,
                      setIsOpen,
                    }) =>
                      NextButton(currentStep, setCurrentStep, steps, setIsOpen)
                    }
                    onClickClose={({
                      currentStep,
                      setCurrentStep,
                      steps,
                      setIsOpen,
                    }) => {
                      setCurrentStep(0);
                      setIsOpen(false);
                    }}
                    components={{ Close }}
                  >
                    <ColorSelector />
                  </TourProvider>
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path='/blue1/*'
            element={
              // <MessageBoxContextProvider>
              <Template1Route />
              // </MessageBoxContextProvider>
            }
          />
          <Route
            path='/blue2/*'
            element={
              // <MessageBoxContextProvider>
              <Template2Route />
              // </MessageBoxContextProvider>
            }
          />
          <Route
            path='/blue3/*'
            element={
              // <MessageBoxContextProvider>
              <Template3Route />
              // </MessageBoxContextProvider>
            }
          />
          <Route path='/dashboard/*' element={<DashBoardRoute />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
