import { useEffect, useState } from "react";
import LiveEdithTextHighLighter from "../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component";
import SiteBuilder from "../../SiteBuilder/SiteBuilder";
import { Description } from "../../Template1/aboutus.style";
import { useLocation, useParams } from "react-router-dom";
import { useMessageContext } from "../../../context/Message.context";
import GetTemplateData from "../../../utils/apis/GetTemplateData.api";
import LiveEditImageHighLighter from "../../../liveEditHighlighterstyles/liveEditImageHighLighter.component";
import { FlexContainer } from "../BlogView/BlogView.style";

export default function Buyerguide({ tempId, templateData }) {
  const [data, setData] = useState(null);
  const location = useLocation();
  const { preview } = useParams();
  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };
  const { setTheme } = useMessageContext();
  const params = getQueryParams();
  console.log(templateData)
  useEffect(() => {
    const getData = async () => {
      if (!templateData) {
        const data = await GetTemplateData(preview, tempId, 4);
        if (data) {
          setData(data);
          setTheme({
            primary_color: data.styles[0].css.primary,
            secondary_color: data.styles[0].css.secondary,
            warning_message: {
              fontFamily: "montserrat",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "20.82px",
            },
            warning_text: {
              fontFamily: "Garamond",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "22.32px",
              textAlign: "center",
            },
            button: {
              fontFamily: "montserrat",
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "18.74px",
            },
          });
        }
      } else {
        setData(templateData);
      }
    };
    getData();
  }, []);

  if (!data)
    return (
      <div id="loadingScreen" className="loading-screen">
        <div className="loader"></div>
      </div>
    );

  const siteBuilder = params.get("siteBuilder");

  const customStyles = `
        :root {
            --primary: ${data.styles[0].css.primary};
            --secondary: ${data.styles[0].css.secondary};
        }
    `;
  const section1 = (
    <div
      className="sec1"
      id="sec1"
      style={{
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(${"/public/" + data.sec1.image})`,
        width: "100%",
        height: "95vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "relative",
      }}
    >
      <div className="info_container">
        {/* Title Section */}
        <LiveEdithTextHighLighter section="sec1" type="text">
          <h1
            style={{
              fontFamily: "montserrat",
              color: data.sec1.color,
            }}
            dangerouslySetInnerHTML={{ __html: data.sec1.text }}
          ></h1>
        </LiveEdithTextHighLighter>

        <LiveEdithTextHighLighter section="sec1" type="description">
          <Description
            $color={data.sec1.color}
            style={{
              fontFamily: "Montserrat",
              color: data.sec1.color,
              paddingTop: "5px",
            }}
            dangerouslySetInnerHTML={{
              __html: data.sec1.description,
            }}
          ></Description>
        </LiveEdithTextHighLighter>
      </div>
    </div>
  );

  const section2 = (
    <div id="sec2">
      <FlexContainer>
        <div className="row">
          {/* Left Column - Image */}
          <div
            className="order-1 col-12 col-md-6"
            style={{ overflow: "hidden", paddingRight: "0" }}
          >
            <LiveEditImageHighLighter section="sec2" type="img">
              <img
                src={"/public/" + data.sec2.img}
                alt=""
                style={{ objectFit: "cover", height: "100%", width: "100%" }}
              />
            </LiveEditImageHighLighter>
          </div>
          {/* Right Column - Text */}
          <div className="order-2 col-12 col-md-6 p-5">
            <LiveEdithTextHighLighter section="sec2" type="text">
              <h2
                style={{ fontFamily: "montserrat" }}
                dangerouslySetInnerHTML={{ __html: data.sec2.text }}
              ></h2>
            </LiveEdithTextHighLighter>

            <LiveEdithTextHighLighter section="sec2" type="description">
              <p
                style={{ fontFamily: "montserrat", color: "black" }}
                dangerouslySetInnerHTML={{ __html: data.sec2.description }}
              ></p>
            </LiveEdithTextHighLighter>
            <br />
            <LiveEdithTextHighLighter section="sec2" type="text2">
              <h2
                style={{ fontFamily: "montserrat" }}
                dangerouslySetInnerHTML={{ __html: data.sec2.text2 }}
              ></h2>
            </LiveEdithTextHighLighter>

            <LiveEdithTextHighLighter section="sec2" type="description2">
              <p
                style={{ fontFamily: "montserrat", color: "black" }}
                dangerouslySetInnerHTML={{ __html: data.sec2.description2 }}
              ></p>
            </LiveEdithTextHighLighter>

            <LiveEdithTextHighLighter section="sec2" type="link">
              <a
                style={{ fontFamily: "montserrat", color: "blue" }}
                href={data.sec2.link}
              >More</a>
            </LiveEdithTextHighLighter>
          </div>
        </div>
      </FlexContainer>
    </div>
  );

  const section3 = (
    <div id="sec3">
      <FlexContainer>
        <div className="row">
          {/* Left Column - Text */}
          <div className="order-1 col-12 col-md-6 p-5">
            <LiveEdithTextHighLighter section="sec3" type="text">
              <h2
                style={{ fontFamily: "montserrat" }}
                dangerouslySetInnerHTML={{ __html: data.sec3.text }}
              ></h2>
            </LiveEdithTextHighLighter>

            <LiveEdithTextHighLighter section="sec3" type="description">
              <p
                style={{ fontFamily: "montserrat", color: "black" }}
                dangerouslySetInnerHTML={{ __html: data.sec3.description }}
              ></p>
            </LiveEdithTextHighLighter>
            <br />
            <LiveEdithTextHighLighter section="sec3" type="text2">
              <h2
                style={{ fontFamily: "montserrat" }}
                dangerouslySetInnerHTML={{ __html: data.sec3.text2 }}
              ></h2>
            </LiveEdithTextHighLighter>

            <LiveEdithTextHighLighter section="sec3" type="description2">
              <p
                style={{ fontFamily: "montserrat", color: "black" }}
                dangerouslySetInnerHTML={{ __html: data.sec3.description2 }}
              ></p>
            </LiveEdithTextHighLighter>

            <LiveEdithTextHighLighter section="sec3" type="link">
              <a
                style={{ fontFamily: "montserrat", color: "blue" }}
                href={data.sec3.link}
              >More</a>
            </LiveEdithTextHighLighter>
          </div>
          {/* Right Column - Image */}
          <div
            className="order-2 col-12 col-md-6"
            style={{ overflow: "hidden", paddingRight: "0" }}
          >
            <LiveEditImageHighLighter section="sec3" type="img">
              <img
                src={"/public/" + data.sec3.img}
                alt=""
                style={{ objectFit: "cover", height: "100%", width: "100%" }}
              />
            </LiveEditImageHighLighter>

          </div>
        </div>
      </FlexContainer>
    </div>
  );

  const section4 = (
    <div id="sec4">
      <FlexContainer>
        <div className="row">
          {/* Left Column - Image */}
          <div
            className="order-1 col-12 col-md-6"
            style={{ overflow: "hidden", paddingRight: "0" }}
          >
            <LiveEditImageHighLighter section="sec4" type="img">
              <img
                src={"/public/" + data.sec4.img}
                alt=""
                style={{ objectFit: "cover", height: "100%", width: "100%" }}
              />
            </LiveEditImageHighLighter>
          </div>
          {/* Right Column - Text */}
          <div className="order-2 col-12 col-md-6 p-5">
            <LiveEdithTextHighLighter section="sec4" type="text">
              <h2
                style={{ fontFamily: "montserrat" }}
                dangerouslySetInnerHTML={{ __html: data.sec4.text }}
              ></h2>
            </LiveEdithTextHighLighter>

            <LiveEdithTextHighLighter section="sec4" type="description">
              <p
                style={{ fontFamily: "montserrat", color: "black" }}
                dangerouslySetInnerHTML={{ __html: data.sec4.description }}
              ></p>
            </LiveEdithTextHighLighter>
            <br />
            <LiveEdithTextHighLighter section="sec4" type="text2">
              <h2
                style={{ fontFamily: "montserrat" }}
                dangerouslySetInnerHTML={{ __html: data.sec4.text2 }}
              ></h2>
            </LiveEdithTextHighLighter>

            <LiveEdithTextHighLighter section="sec4" type="description2">
              <p
                style={{ fontFamily: "montserrat", color: "black" }}
                dangerouslySetInnerHTML={{ __html: data.sec4.description2 }}
              ></p>
            </LiveEdithTextHighLighter>
            <LiveEdithTextHighLighter section="sec4" type="link">
              <a
                style={{ fontFamily: "montserrat", color: "blue" }}
                href={data.sec4.link}
              >More</a>
            </LiveEdithTextHighLighter>
          </div>
        </div>
      </FlexContainer>
    </div>
  );

  const section5 = (
    <div id="sec5">
      <FlexContainer>
        <div className="row">
          {/* Left Column - Text */}
          <div className="order-1 col-12 col-md-6 p-5">
            <LiveEdithTextHighLighter section="sec5" type="text">
              <h2
                style={{ fontFamily: "montserrat" }}
                dangerouslySetInnerHTML={{ __html: data.sec5.text }}
              ></h2>
            </LiveEdithTextHighLighter>

            <LiveEdithTextHighLighter section="sec5" type="description">
              <p
                style={{ fontFamily: "montserrat", color: "black" }}
                dangerouslySetInnerHTML={{ __html: data.sec5.description }}
              ></p>
            </LiveEdithTextHighLighter>
            <br />
            <LiveEdithTextHighLighter section="sec5" type="text2">
              <h2
                style={{ fontFamily: "montserrat" }}
                dangerouslySetInnerHTML={{ __html: data.sec5.text2 }}
              ></h2>
            </LiveEdithTextHighLighter>

            <LiveEdithTextHighLighter section="sec5" type="description2">
              <p
                style={{ fontFamily: "montserrat", color: "black" }}
                dangerouslySetInnerHTML={{ __html: data.sec5.description2 }}
              ></p>
            </LiveEdithTextHighLighter>
            <LiveEdithTextHighLighter section="sec5" type="link">
              <a
                style={{ fontFamily: "montserrat", color: "blue" }}
                href={data.sec5.link}
              >More</a>
            </LiveEdithTextHighLighter>
          </div>
          {/* Right Column - Image */}
          <div
            className="order-2 col-12 col-md-6"
            style={{ overflow: "hidden", paddingRight: "0" }}
          >
            <LiveEditImageHighLighter section="sec5" type="img">
              <img
                src={"/public/" + data.sec5.img}
                alt=""
                style={{ objectFit: "cover", height: "100%", width: "100%" }}
              />
            </LiveEditImageHighLighter>
          </div>
        </div>
      </FlexContainer>
    </div>
  );

  const section6 = (
    <div id="sec6">
      <FlexContainer>
        <div className="row">
          {/* Left Column - Image */}
          <div
            className="order-1 col-12 col-md-6"
            style={{ overflow: "hidden", paddingRight: "0" }}
          >
            <LiveEditImageHighLighter section="sec6" type="img">
              <img
                src={"/public/" + data.sec6.img}
                alt=""
                style={{ objectFit: "cover", height: "100%", width: "100%" }}
              />
            </LiveEditImageHighLighter>
          </div>
          {/* Right Column - Text */}
          <div className="order-2 col-12 col-md-6 p-5">
            <LiveEdithTextHighLighter section="sec6" type="text">
              <h2
                style={{ fontFamily: "montserrat" }}
                dangerouslySetInnerHTML={{ __html: data.sec6.text }}
              ></h2>
            </LiveEdithTextHighLighter>

            <LiveEdithTextHighLighter section="sec6" type="description">
              <p
                style={{ fontFamily: "montserrat", color: "black" }}
                dangerouslySetInnerHTML={{ __html: data.sec6.description }}
              ></p>
            </LiveEdithTextHighLighter>
            <br />
            <LiveEdithTextHighLighter section="sec6" type="text2">
              <h2
                style={{ fontFamily: "montserrat" }}
                dangerouslySetInnerHTML={{ __html: data.sec6.text2 }}
              ></h2>
            </LiveEdithTextHighLighter>

            <LiveEdithTextHighLighter section="sec6" type="description2">
              <p
                style={{ fontFamily: "montserrat", color: "black" }}
                dangerouslySetInnerHTML={{ __html: data.sec6.description2 }}
              ></p>
            </LiveEdithTextHighLighter>
            <LiveEdithTextHighLighter section="sec6" type="link">
              <a
                style={{ fontFamily: "montserrat", color: "blue" }}
                href={data.sec6.link}
              >More</a>
            </LiveEdithTextHighLighter>
          </div>
        </div>
      </FlexContainer>
    </div>
  );

  // const section7 = (
  //     <div>
  //         <div className="info_container">
  //             <LiveEdithTextHighLighter
  //                 section="sec7"
  //                 type="text">
  //                 <div
  //                     dangerouslySetInnerHTML={{ __html: data.sec7.text }}></div>
  //             </LiveEdithTextHighLighter>

  //             <LiveEdithTextHighLighter
  //                 section="sec7"
  //                 type="description">
  //                 <div
  //                     dangerouslySetInnerHTML={{
  //                         __html: data.sec7.description,
  //                     }}></div>
  //             </LiveEdithTextHighLighter>
  //         </div>
  //     </div>
  // );

  const content = (
    <div className="template1" style={{ height: "100%" }}>
      <style>{customStyles}</style>
      {data.sec1.visible && section1}
      {data.sec2.visible && section2}
      {data.sec3.visible && section3}
      {data.sec4.visible && section4}
      {data.sec5.visible && section5}
      {data.sec6.visible && section6}
      {/* {data.sec7.visible && section7}  */}
      {/* {data.sec8.visible && section8} */}
      {/* {data.sec9.visible && section9} */}
    </div>
  );
  return (
    <div>
      <SiteBuilder
        {...{
          template: content,
          siteBuilder: siteBuilder,
          data,
          setData,
        }}></SiteBuilder>
    </div>
  );
}
