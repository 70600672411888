/** @format */

import { buttons } from "polished";
import { useState } from "react";
import { Nav } from "react-bootstrap";
import {
  Container,
  MobileLinks,
  MobileSection,
  NavLink,
  NavLinkContainer,
} from "./navbar.style";

type NavbarProps = {
  data?: Record<string, any>;
};

export default function Navbar({ data }: NavbarProps) {
  const [open, setOpen] = useState(true);
  const handleOpenClose = () => {
    setOpen((prev) => !prev);
  };

  const handleScrollToPropertyCities = () => {
    const propertyCitiesElement = document.getElementById("Property_cards");
    if (propertyCitiesElement) {
      propertyCitiesElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Container id="header">
        {/* <Link to={'/template2/home'} className="logo" >
          {data?.text !== 'LOGO' ? <img
            src={`/public/${data?.text}`}
            alt="Logo"
          /> : <img src={Logo} alt='Logo' />}

        </Link> */}
        <NavLinkContainer>
          <NavLink to="../home">Home</NavLink>
          <NavLink to="../about_us">About us</NavLink>
          {/* <NavLink to="../idxlisting" onClick={handleScrollToPropertyCities}>
            Browse Homes
          </NavLink> */}
          {/* <NavLink to={"../map"}>Map</NavLink> */}
          {/* <NavLink to={"../contact_us"}>Contact us</NavLink> */}
          <div className="dropdown ">
            <a
              className="dropdown-toggle"
              href="#"
              id="sellers"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Sellers
            </a>
            <ul className="dropdown-menu" aria-labelledby="sellers">
              <li>
                <NavLink to={"../sellerguide"}>Sellers</NavLink>
              </li>
              <li>
                <NavLink to={"../sellersguide"}>Home Valuation</NavLink>
              </li>
              <li>
                <NavLink to={"../blogs"}>Blogs</NavLink>
              </li>
            </ul>
          </div>
          <div className="dropdown ">
            <a
              className="dropdown-toggle"
              href="#"
              id="buyers"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Buyers
            </a>
            <ul className="dropdown-menu" aria-labelledby="buyers">
              <li>
                <NavLink to={"../buyerguide"}>Buyers</NavLink>
              </li>
              <li>
                <NavLink to={"../mortagecalculator"}>
                  Mortage Calculator
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="dropdown">
            <a
              className="dropdown-toggle"
              href="#"
              id="browse_home"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Browse Homes
            </a>
            <ul className="dropdown-menu" aria-labelledby="browse_home">
              <li>
                <NavLink to={"../exclusivecards"}>Exclusive listing</NavLink>
              </li>
              <li>
                <NavLink to={"../featuredcards"}>Featured Listing</NavLink>
              </li>
              <li>
                <NavLink
                  to="../idxlisting"
                  onClick={handleScrollToPropertyCities}
                >
                  Idx Listing
                </NavLink>
              </li>
              <li>
                <NavLink to={"../soldlisting"}>Sold Listing</NavLink>
              </li>
              <li>
                <NavLink to={"../builderdeals"}>Builder Listing</NavLink>
              </li>
              <li>
                <NavLink to={"../map"}>Map</NavLink>
              </li>
            </ul>
          </div>
          <NavLink to={"../contact_us"}>Contact us</NavLink>
        </NavLinkContainer>
        <MobileSection isOpen={open}>
          {open ? (
            <div className="svg_wrapper" onClick={handleOpenClose}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 3.99976H21V5.99976H3V3.99976ZM3 10.9998H21V12.9998H3V10.9998ZM3 17.9998H21V19.9998H3V17.9998Z"
                  fill="white"
                />
              </svg>
            </div>
          ) : (
            <div className="svg_wrapper" onClick={handleOpenClose}>
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 7L18 18M7 18L18 7"
                  stroke="white"
                  stroke-width="2.08333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          )}
        </MobileSection>
        <MobileLinks isOpen={open}>
          <NavLink to={"../home"} onClick={handleOpenClose}>
            Home
          </NavLink>
          <NavLink to={"../about_us"} onClick={handleOpenClose}>
            About us
          </NavLink>
          <NavLink to={"../idxlisting"} onClick={handleOpenClose}>
            Browse Homes
          </NavLink>
          <NavLink to={"../contact_us"} onClick={handleOpenClose}>
            Contact us
          </NavLink>
          <NavLink to={"../map"} onClick={handleOpenClose}>
            Map
          </NavLink>
        </MobileLinks>
      </Container>
    </>
  );
}
