/** @format */

import { type Marker, MarkerClusterer } from "@googlemaps/markerclusterer";
import { InfoWindow, useMap } from "@vis.gl/react-google-maps";
import { useCallback, useEffect, useMemo, useState } from "react";
import PropertyMarker from "./property-marker";
import { PropertyType } from "./Property.component";
import "./RealtyCard.css";
import PropertyCard, {
  PROPERTY_TYPE_CLASSES,
} from "../../PropertyCard/PropertyCard.utilComponent";
import { ImageConverter } from "../../idxView/idxhelperfunctions";
import ConvertToCanadianNumber from "../../../../utils/New_construction_details/ConvertNumberToDollar.util";
import justimg from "../../../Template2/components/ImageRedirector/Images/ourTeam.jpg";
import { MdHeight } from "react-icons/md";
export type ClusterPropertyMarkersProps = {
  properties: PropertyType[];
  cardType: PROPERTY_TYPE_CLASSES;
};

export default function ClusterPropertyMarkers({
  properties,
  cardType,
}: ClusterPropertyMarkersProps) {
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
  const [selectedPropertyKey, setSelectedPropertyKey] = useState<string | null>(
    null,
  );

  const selectedProperty = useMemo(
    () =>
      properties && selectedPropertyKey
        ? properties.find((p) => String(p.unique_id) === selectedPropertyKey)!
        : null,
    [properties, selectedPropertyKey],
  );
  const map = useMap();
  const clustered = useMemo(() => {
    if (!map) return null;
    return new MarkerClusterer({ map });
  }, [map]);
  useEffect(() => {
    if (!clustered) return;
    clustered.clearMarkers();
    clustered.addMarkers(Object.values(markers));
  }, [clustered, markers]);

  const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;

        return newMarkers;
      }
    });
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedPropertyKey(null);
  }, []);
  const handleMarkerClick = useCallback((properties: PropertyType) => {
    setSelectedPropertyKey(String(properties.unique_id));
  }, []);
  const img = ImageConverter(selectedProperty?.photos, "idx");
  const price = ConvertToCanadianNumber(selectedProperty?.list_price);
  return (
    <div>
      {properties?.map((property) => (
        <PropertyMarker
          key={String(property.unique_id)}
          property={property}
          onClick={handleMarkerClick}
          setMarkerRef={setMarkerRef}
        />
      ))}
      {selectedPropertyKey && (
        <InfoWindow
          anchor={markers[selectedPropertyKey]}
          onCloseClick={handleInfoWindowClose}
        >
          <div className="realty-card">
            <div
              id={`realtyCarousel${selectedProperty.unique_id}`}
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={(img && img[0]) || justimg}
                    className="d-block w-100"
                    style={{ maxHeight: "160px" }}
                    alt="Realty Image 1"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={(img && img[1]) || justimg}
                    className="d-block w-100"
                    style={{ maxHeight: "160px" }}
                    alt="Realty Image 2"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={(img && img[2]) || justimg}
                    className="d-block w-100"
                    style={{ maxHeight: "160px" }}
                    alt="Realty Image 3"
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target={`#realtyCarousel${selectedProperty.unique_id}`}
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target={`#realtyCarousel${selectedProperty.unique_id}`}
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>

            <div className="realty-info">
              <div className="realty-price">{price}</div>
              <p>{(selectedProperty.address, selectedProperty.city)}</p>
              <div className="realty-features">
                <span>
                  🛏 <b>{selectedProperty.bedrooms}</b> bed
                </span>
                <span>
                  🛁 <b>{selectedProperty.bathrooms}</b> bath
                </span>
                <span>
                  🚗 <b>{selectedProperty.garage}</b> garage
                </span>
              </div>
            </div>
          </div>
          {/* <div>
            <PropertyCard
              propertyCardType={cardType}
              propertyType={'idx'}
              propertyData={{
                id: selectedProperty.unique_id.toString(),
                img: ImageConverter(selectedProperty.photos, 'idx'),
                price: ConvertToCanadianNumber(selectedProperty.list_price),
                title: `${selectedProperty.address} ${selectedProperty.city}`,
                bedrooms: selectedProperty.bedrooms.toString(),
                bathrooms: selectedProperty.bathrooms.toString(),
                garage: selectedProperty?.garage.toString(),
                sale_or_lease: selectedProperty.sale_or_lease,
                redirect: `idxView`,
              }}
              key={selectedProperty.unique_id}
            />
          </div> */}
          {/* {selectedProperty?.list_price} */}
        </InfoWindow>
      )}
    </div>
  );
}
