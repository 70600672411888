/** @format */

import styled from "styled-components";

import { Link } from "react-router-dom";

export const Container = styled.div`
  font-family: "Syncopate";
  background-color: ${(props) => props.theme.colors.primary};
  padding: clamp(0.25rem, 2vw, 1.5rem) clamp(0.25rem, 3vw, 3rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 5.5rem;
  position: sticky;
  top: 0;
  z-index: 1050;
  .logo {
    font-size: clmap(1rem, 1.5rem, 1.5rem);
    color: ${(props) => props.theme.colors.text};
    text-decoration: none;

    img {
      width: 5rem;
      height: 5rem;
      object-fit: contain;
    }
  }
`;
export const NavLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: clamp(1rem, 2.5vw, 2.5rem);
  @media screen and (max-width: 455px) {
    display: none;
  }
  .dropdown {
    a {
      text-decoration: none;
      color: white;
    }
    ul {
      li {
        padding-left: 10px;
        a {
          width: 100%;
          text-align: center;
          color: ${(props) => props.theme.colors.primary};
          font-size: 12px;
        }
      }
    }
  }
`;
export const NavLink = styled(Link)`
  font-family: "Syncopate";
  text-decoration: none;
  color: ${(props) => props.theme.colors.text};
  @media screen and (max-width: 455px) {
    font-size: 1.5rem;
  }
`;

type CustomProps = {
  isOpen: boolean;
};

export const MobileLinks = styled.div<CustomProps>`
  position: absolute;
  top: 5rem;
  height: calc(100vh - 5rem);
  width: 100%;
  padding: 2rem;
  background: black;
  z-index: 100;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  left: ${(props) => (props.isOpen ? 100 : 0)}%;

  transition: all 0.3s ease-in-out;
  display: none;
  overflow: hidden;
  @media screen and (max-width: 455px) {
    display: ${(props) => (props.isOpen ? "none" : "flex")};
  }
`;
export const MobileSection = styled.div<CustomProps>`
  display: none;
  .svg_wrapper {
  }

  @media screen and (max-width: 455px) {
    display: block;
  }
`;
