/** @format */

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 0;
  margin: 0; 
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  background-color: transparent;
  box-sizing: border-box;

  .row {
    display: flex;
    gap: 0.3rem;
    width: 100%;
    margin: 0; 
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .images {
    position: relative;
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(50% - 0.15rem); 
    height: 280px;
    background-color: transparent;
    box-sizing: border-box; 

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .link {
      font-family: Syncopate, sans-serif;
      position: absolute;
      color: white;
      font-size: clamp(1rem, 2vw, 2.125rem);
      text-decoration: none;
      font-weight: 500;
      z-index: 1;
      transition: all 0.3s;
    }

    &::before {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0.4;
      background-color: black;
    }

    .horizontal-line {
      position: absolute;
      height: 2px;
      background-color: white;
      width: 0;
      transition: width 0.4s;
    }

    .top-line {
      top: 20%;
      left: 0;
      right: 0;
      margin: auto;
    }

    .bottom-line {
      bottom: 20%;
      left: 0;
      right: 0;
      margin: auto;
    }

    &:hover {
      .horizontal-line {
        width: 100%;
      }
    }

    &:hover .link {
      transform: translateY(-10px);
    }
  }

  @media screen and (max-width: 768px) {
    .row {
      flex-direction: column;
    }

    .images {
      width: 100%;
    }
  }
`;
export const PopupFormContainer = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  display: flex;
  justify-content: center;
  align-items: center;
`;
type PopupFormsProps = {
  $disabledCheck: boolean;
};

export const PopupForm = styled.form<PopupFormsProps>`
  width: min(25rem, 95%);
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
  h3 {
    text-align: center;
  }
  .warning {
    text-align: center;
    color: red;
  }
  .Input {
    display: block;
    width: 100%;
    padding: 0.3rem 0.8rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    border: 1px solid black;
    resize: none;
  }
  .TextArea {
    height: 9rem;
  }
  .CheckBox {
    display: flex;
    align-items: baseline;
    gap: 0.8rem;
    margin-bottom: 0.8rem;
    p {
      color: ${(props) =>
        props.theme?.colors?.primary
          ? props.theme?.colors?.primary
          : "var(--primary)"};
    }
  }
  .buttons {
    display: flex;
    gap: 1rem;
    .button {
      border: none;
      width: 100%;
      background-color: ${(props) =>
        props.theme?.colors?.primary
          ? props.theme?.colors?.primary
          : "var(--primary)"};
      color: white;
      border-radius: 0.4rem;
      padding: 0.45rem;
      &.submit_button {
        cursor: ${(props) =>
          props.$disabledCheck ? "not-allowed" : "pointer"};
      }
    }
  }
`;
