/** @format */

import styled from 'styled-components';
import { keyframes } from 'styled-components';

type ContainerProps = {
  $color?: string;
};

//  fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideInFromBottom = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-50px) scale(0.9) rotate(-10deg); 
    opacity: 0;
  }
  to {
    transform: translateX(0) scale(1) rotate(0); 
    opacity: 1;
  }
`;

export const Container = styled.div<ContainerProps>`
  font-family: 'Syncopate';
  width: min(100%, 120rem);
  margin: 0 auto; 
  .HomeContainer {
    position: relative;
    overflow: hidden;
    // aspect-ratio:16/9;
    height: 41vw;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    z-index: 1; 
  }

  .leftSection {
    position: relative; 
    z-index: 2;
    width: 60%;
    padding: 2rem;
    top: 50%;
    transform: translateY(-50%); 
    // animation: ${slideInFromLeft} 3s ease-out;
  }

  .Text {
    font-size: clamp(2rem, 3vw, 3rem);
    font-weight: 500;
    color: ${(props) => props.$color};
    text-transform: uppercase;
    margin: 0;
    animation: ${slideInFromBottom} 3s ease-out;
  }

  .description {
    font-weight: 400;
    color: ${(props) => props.$color};
    font-size: clamp(0.75rem, 1.3vw, 1.3125rem);
    margin-bottom: clamp(1rem, 1.5vw, 1.5rem);
    text-align: left;
    animation: ${slideInFromBottom} 3s ease-out;
  }

   button {
      padding: clamp(0.5rem, 1vw, 1rem);
      display: flex;
      align-items: center;
      gap: 1rem;
      font-weight: 700;
      font-size: clamp(0.75rem, 1vw, 1.125rem);
      text-transform: uppercase;
      background-color: transparent;
      color: #333;
      color: ${(props) => props.$color};
      border: 3px solid white;
      border-radius: 0.5rem;
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease;
      svg {
        path {
          fill: ${(props) => props.$color};
        }
      }
    }

    button:hover {
      background-color: #333;
      color: #fff;
    }

    button:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(0, 0, 255, 0.3);
    }
  }
  .backgroundImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0; 
  }

  @media screen and (max-width: 991px) {
    .leftSection {
      width: 100%;
      position: relative;
    }

    .rightSection {
      width: 100%;
      position: absolute;
    }
  }

  @media screen and (max-width: 689px) {
    .HomeContainer {
      flex-direction: column;
      gap: 1rem;
    }

    .leftSection {
      width: 100%;
      padding: 1rem;
    }

    .rightSection {
      width: 100%;
      height: 100%;
    }
  }
`;
