/** @format */

import styled from 'styled-components';

type BackgroundProps = {
  imageUrl: string; // Required prop for the image URL
};

export const MainContainer = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
`;

export const Container = styled.div<BackgroundProps>`
  width: min(120rem, 100%);
  margin: 0 auto;
  font-family: 'Syncopate';
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  gap: 1rem;
  h1,
  p {
    font-family: 'Cormorant Garamond';
  }
  .svg_wrapper {
    position: absolute;
    z-index: 100;
    top: 2%;
    right: 2%;
    cursor: pointer;
  }
  /* &::before {
    content: '';
    position: absolute;
    background-color: black;
    opacity: 0.6;
    height: 100%;
    width: 100%;
    z-index: 0;
  } */
  .mobile_view_image {
    /* display: none; */
    flex: 0.6;
    position: relative;
    height: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::before {
      content: '';
      position: absolute;
      background-color: black;
      opacity: 0.3;
      height: 100%;
      height: fit-content;
      width: 100%;
      z-index: 0;
    }
  }
  @media screen and (max-width: 1023px) {
    background-image: none;
    background-color: white;
    flex-direction: column;

    .mobile_view_image {
      /* height: 55vh; */
      /* width: min(35rem, 100%); */
      height: 50vh;
      max-height: 694px;
      flex: none;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &::before {
      display: none;
    }
  }
`;
export const ContactContainer = styled.div`
  position: relative;
  z-index: 1;
  width: min(95%, 85rem);
  margin: 1rem auto;
  flex: 1;
  .contact_us_heading,
  .contact_us_heading p {
    text-align: center;
    font-size: clamp(1.5rem, 2vw, 2rem);
    color: white;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  .contact_us_container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    gap: 2rem;
    padding: clamp(1rem, 2vw, 2rem);
  }
  h1 {
    font-size: clamp(1.5rem, 2vw, 2rem);
    color: white;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  h3 {
    font-size: clamp(1rem, 2vw, 2rem);
    color: white;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  .bar {
    height: 65%;
    width: 0.15rem;
    background-color: white;
  }

  @media screen and (max-width: 1023px) {
    h1,
    h3,
    p {
      color: black;
    }
    .form_label {
      color: black;
    }
    .bar {
      background-color: black;
    }
    .contact_us_container {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 634px) {
    flex-direction: column;
    align-items: flex-start;
    .bar {
      width: 100%;
      height: 0.15rem;
    }
  }
`;

export const ContactDetails = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 50%;
  height: 70%;
  flex: 1;
  .heading {
    h1 {
      margin-bottom: 2.8rem;
    }
  }
  .contactInfo {
    margin-bottom: 1.8rem;
    h1 {
      font-size: clamp(1rem, 2vw, 2rem);
      color: white;
      margin: 0;
    }
    p {
      font-size: clamp(0.875rem, 1.5vw, 1.5rem);
      color: white;
      margin: 0;
      word-break: break-all;
      word-wrap: break-word;
      text-align: left;
    }
    .contact_info,
    .contact_info p {
      font-size: clamp(0.875rem, 1.5vw, 1.5rem);
      color: white;
      margin: 0;
      word-break: break-all;
      word-wrap: break-word;
      text-align: left;
    }
  }

  @media screen and (max-width: 1023px) {
    .contactInfo {
      h1,
      h3,
      p {
        color: black;
      }
      .form_label {
        color: black;
      }
    }
  }
  @media screen and (max-width: 634px) {
    width: 100%;
  }
`;

export const SumbitForm = styled.form`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 50%;
  height: 70%;
  flex: 1;
  .inputINfo {
    margin-bottom: 1rem;
    .input {
      background-color: transparent;
      border: none;
      outline: none;
      border-bottom: 1px solid white;
      width: 100%;
      color: white;
    }
    .form_label {
      font-family: Libre Baskerville;
      font-size: 1.3288rem;
      font-weight: 400;
      line-height: 37.2px;
      color: #ffffff;
    }
    p {
      font-family: Libre Baskerville;
      font-size: 1.3288rem;
      font-weight: 400;
      line-height: 37.2px;
      color: #ffffff;
    }
  }
  .submit_form {
    width: fit-content;
    background: transparent;
    border: 1px solid white;
    color: white;
    padding: 0.75rem 2rem;
    svg {
      margin-left: 0.75rem;
    }
  }
  @media screen and (max-width: 1023px) {
    .submit_form {
      border-color: black;
      color: black;
      svg {
        path {
          fill: black;
        }
      }
    }
    .inputINfo {
      .input {
        color: black;
        border-bottom: 1px solid black;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .inputINfo {
      .form_label {
        color: black;
      }
    }
  }
  @media screen and (max-width: 634px) {
    width: 100%;
  }
`;

export const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .message {
    background-color: white;
    border-radius: 12px;
    padding-block: 1rem;
    padding-inline: 1.5rem;
    height: 35rem;
    width: 35rem;
    p {
      font-size: clamp(1rem, 1.5vw, 1.5rem);
      color: black;
    }
  }
`;
