
import usePermissionSoldProperty from '../../CustomHooks/usePermissionSoldProperty.customHook';
import SoldHomeForm from '../SoldHomeForm/SoldHomeForm.component';
import { useNavigate } from 'react-router-dom';
import './soldproperty.css'
export default function SoldProperty({ PropertyData }) {
  const Navigate = useNavigate();
  const {
    user,
    open,
    handleOpenClose,
    handleNavigation,
    handleSubmitForm,
    handleState,
  } = usePermissionSoldProperty();
  const HandleNavigation = () => {
    if (!user) {
      handleNavigation();
      return;
    }
    Navigate(`${PropertyData.redirect}/${PropertyData.id}`);
  };
  return (
    <>
      <div className='altman-properties-item' data-aos="slide-right" onClick={HandleNavigation}>
        <div className='atlman-sub'>
          <div className='altman-properties-item-inner-wrapper'>
            <div className='altman-properties-item-photo'>
              <canvas
                width='529'
                height='460'
                className='lazy-load-canvas-element'
                data-lazyload-src={`${PropertyData.img[0]}`}
                style={{
                  backgroundImage: `url(${PropertyData.img[0]})`,
                }}
              ></canvas>
            </div>
            <div className='altman-properties-item-content-status-city ease'>
              <div>{PropertyData.sale_or_lease}</div>
              <div>{PropertyData.city}</div>
            </div>
            <div className='altman-properties-item-content ease'>
              <div className='altman-properties-item-content-address'>
                {PropertyData.title}
                <div>{PropertyData.city}</div>
              </div>
              <ul className='altman-properties-item-content-features ease ps-0'>
                <li>{PropertyData.bedrooms} BD</li>
                <li>{PropertyData.bathrooms} BA</li>
                <li>{PropertyData.garage} GA</li>
              </ul>
              <div className='altman-properties-item-content-price ease'>
                ${PropertyData.price}
              </div>
            </div>
            <div className='altman-properties-item-link-label ease' onClick={HandleNavigation}>
              View Details
            </div>
            {!user && <div className='soldpro ease'>
              <svg
                aria-hidden='true'
                focusable='false'
                data-prefix='fas'
                data-icon='lock'
                className='svg-inline--fa fa-lock icon_lock'
                role='img'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 448 512'
              >
                <path
                  fill='currentColor'
                  d='M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z'
                ></path>
              </svg>
              <h3 className='my-1'>Free Account Required</h3>
              <p>Unlock to view more listings</p>
              <button className='btn btn-sm btn-outline-light' onClick={HandleNavigation}>Unlock</button>
            </div>}
          </div>
        </div>
      </div>
      {open && (
        <SoldHomeForm handleOpenClose={handleOpenClose} handleState={handleState} handleSubmitForm={handleSubmitForm} />
      )
      }
    </>
  )
}
