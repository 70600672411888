/** @format */

import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import { convertToHTML } from "draft-convert";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import Cookies from "js-cookie";
import { Editor } from "react-draft-wysiwyg";
import "./Editor.css";

export default function Editors({ setEditor, header, _id, fetchData }) {
  const imageFile = useRef(null);
  const imageFile2 = useRef(null);
  const imageFile3 = useRef(null);
  // const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    description2: "",
    description3: "",
    coverImage: "",
    descImage1: "",
    descImage2: "",
  });
  const [description2, setDescription2] = useState(() =>
    EditorState.createEmpty(),
  );

  const [description3, setDescription3] = useState(() =>
    EditorState.createEmpty(),
  );
  //handleinput
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  //handlesave
  const handleSave = async () => {
    const token = Cookies.get("token");
    const data = {
      metaTitle: "formData.title",
      metaDescription: "contact description",
      keywords: "contact,request",
      title: formData.title,
      // description: convertToHTML(description.getCurrentContent()),
      description2: convertToHTML(description2.getCurrentContent()),
      description3: convertToHTML(description3.getCurrentContent()),
      coverImage: file,
      descImage1: file2,
      descImage2: file3,
    };
    try {
      await axios.post("/admin/add-blog", data, {
        headers: {
          "x-access-token": token,
        },
      });
      setEditor("idle");
      fetchData();
    } catch (error) {
      console.error(error);
    }
  };

  //handleImageChage
  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      const data = await handleUploadImage(file);
      if (type === "file") {
        setFile(data);
      } else if (type === "file2") {
        setFile2(data);
      } else {
        setFile3(data);
      }
      reader.readAsDataURL(file);
    }
  };

  const handleUploadImage = async (Imagedata) => {
    const token = Cookies.get("token");
    const data = new FormData();
    data.append("image", Imagedata);
    try {
      const Data = await axios.post("/api/upload", data, {
        headers: {
          "x-access-token": token,
        },
      });
      return Data.data.file;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const token = Cookies.get("token");

    const fetchBlog = async () => {
      if (_id !== null) {
        try {
          const data = await axios.get(`/admin/blog/${_id}`, {
            headers: {
              "x-access-token": token,
            },
          });

          const {
            title,
            description2,
            description3,
            coverImage,
            descImage2,
            descImage1,
          } = data.data.data;
          setFormData({ ...formData, title: title });

          const Description2 = convertFromHTML(description2);
          const Description3 = convertFromHTML(description3);

          const contentState2 = ContentState.createFromBlockArray(
            Description2.contentBlocks,
            Description2.entityMap,
          );

          const contentState3 = ContentState.createFromBlockArray(
            Description3.contentBlocks,
            Description3.entityMap,
          );
          setDescription2(EditorState.createWithContent(contentState2));
          setDescription3(EditorState.createWithContent(contentState3));
          setFile(coverImage);
          setFile2(descImage1);
          setFile3(descImage2);
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchBlog();
  }, [_id]);
  console.log(file, file2, file3, description2, description3);
  //handle Edit update
  const handleUpdate = async () => {
    const token = Cookies.get("token");
    const data = {
      blogId: _id,
      metaTitle: "formData.title",
      metaDescription: "contact description",
      keywords: "contact,request",
      title: formData.title,
      // description: convertToHTML(description.getCurrentContent()),
      description2: convertToHTML(description2.getCurrentContent()),
      description3: convertToHTML(description3.getCurrentContent()),
      coverImage: file,
      descImage1: file2,
      descImage2: file3,
    };
    try {
      await axios.post(`/admin/blog-update`, data, {
        headers: {
          "x-access-token": token,
        },
      });
      fetchData();
      setEditor("idle");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="edit_container" onClick={(e) => e.stopPropagation()}>
        <div className="edit_header d-flex justify-content-between align-items-center">
          <p>{`${header} post`}</p>
          <div className="edit_action">
            <div
              className="cancle d-flex align-items-center gap-2"
              onClick={() => setEditor("idle")}
            >
              <p>cancle</p>
              <div className="wrapper ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M13.6464 1.76355C13.8417 1.56829 13.8417 1.25171 13.6464 1.05645L12.9436 0.353553C12.7483 0.158291 12.4317 0.158291 12.2364 0.353553L7.35355 5.23645C7.15829 5.43171 6.84171 5.43171 6.64645 5.23645L1.76355 0.353553C1.56829 0.158291 1.25171 0.158291 1.05645 0.353553L0.353553 1.05645C0.158291 1.25171 0.158291 1.56829 0.353553 1.76355L5.23645 6.64645C5.43171 6.84171 5.43171 7.15829 5.23645 7.35355L0.353553 12.2364C0.158291 12.4317 0.158291 12.7483 0.353553 12.9436L1.05645 13.6464C1.25171 13.8417 1.56829 13.8417 1.76355 13.6464L6.64645 8.76355C6.84171 8.56829 7.15829 8.56829 7.35355 8.76355L12.2364 13.6464C12.4317 13.8417 12.7483 13.8417 12.9436 13.6464L13.6464 12.9436C13.8417 12.7483 13.8417 12.4317 13.6464 12.2364L8.76355 7.35355C8.56829 7.15829 8.56829 6.84171 8.76355 6.64645L13.6464 1.76355Z"
                    fill="#999999"
                  />
                </svg>
              </div>
            </div>
            <div
              className="save d-flex align-items-center gap-2"
              onClick={() => {
                if (_id === null) {
                  handleSave();
                } else {
                  handleUpdate();
                }
              }}
            >
              <p> {_id === null ? "Save" : "Update"}</p>
              <div className="wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <path
                    d="M21.9875 4.4875C21.5125 4.0125 20.875 3.75 20.2125 3.75H6.25C5.58696 3.75 4.95107 4.01339 4.48223 4.48223C4.01339 4.95107 3.75 5.58696 3.75 6.25V23.75C3.75 25.125 4.875 26.25 6.25 26.25H23.75C25.125 26.25 26.25 25.125 26.25 23.75V9.7875C26.25 9.125 25.9875 8.4875 25.5125 8.025L21.9875 4.4875ZM15 23.75C12.925 23.75 11.25 22.075 11.25 20C11.25 17.925 12.925 16.25 15 16.25C17.075 16.25 18.75 17.925 18.75 20C18.75 22.075 17.075 23.75 15 23.75ZM16.25 11.25H8.75C7.375 11.25 6.25 10.125 6.25 8.75C6.25 7.375 7.375 6.25 8.75 6.25H16.25C17.625 6.25 18.75 7.375 18.75 8.75C18.75 10.125 17.625 11.25 16.25 11.25Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="edit_info">
          <div className="title_info d-flex justify-content-between">
            <div className="title_block">
              <p>Title</p>
              <input
                type="text"
                name="title"
                id=""
                className="info"
                value={formData.title}
                onChange={handleInput}
              />
            </div>
            <div className="image_container">
              {file && <img src={`/public/${file}`} alt="Selected" />}
              <div
                className="upload"
                onClick={() => imageFile.current?.click()}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, "file")}
                  style={{ display: "none" }}
                  id="imageInput"
                  ref={imageFile}
                />
                <div className="wrapper">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="30"
                    viewBox="0 0 31 30"
                    fill="none"
                  >
                    <path
                      d="M14.25 25H8.625C6.725 25 5.10417 24.3458 3.7625 23.0375C2.42083 21.7208 1.75 20.1167 1.75 18.225C1.75 16.6 2.2375 15.15 3.2125 13.875C4.19583 12.6 5.47917 11.7875 7.0625 11.4375C7.5875 9.52083 8.62917 7.97083 10.1875 6.7875C11.7542 5.59583 13.525 5 15.5 5C17.9417 5 20.0083 5.85 21.7 7.55C23.4 9.24167 24.25 11.3083 24.25 13.75C25.6917 13.9167 26.8833 14.5417 27.825 15.625C28.775 16.6917 29.25 17.9417 29.25 19.375C29.25 20.9417 28.7042 22.2708 27.6125 23.3625C26.5208 24.4542 25.1917 25 23.625 25H16.75V16.0625L18.75 18L20.5 16.25L15.5 11.25L10.5 16.25L12.25 18L14.25 16.0625V25Z"
                      fill="#999999"
                    />
                  </svg>
                </div>
                <p>Upload Image</p>
              </div>
            </div>
          </div>

          {/* <div className="title_description">
            <p>Description</p>
            <Editor
              editorState={description}
              onEditorStateChange={setDescription}
              wrapperClassName="wrapper-class"
              toolbarClassName="toolbar-class"
              editorClassName="editor-class"
              toolbar={{
                options: ['inline', 'textAlign', 'link', 'blockType', 'list'],
              }}
            />
          </div> */}
        </div>
        <div className="edit_info">
          <div className="title_info d-flex justify-content-between">
            {/* <div className="title_block">
              <p>Title</p>
              <input
                type="text"
                name="title"
                id=""
                className="info"
                value={formData.title}
                onChange={handleInput}
              />
            </div> */}
            <div className="image_container image_full_container">
              {file2 && <img src={`/public/${file2}`} alt="Selected" />}
              <div
                className="upload"
                onClick={() => imageFile2.current?.click()}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, "file2")}
                  style={{ display: "none" }}
                  id="imageInput"
                  ref={imageFile2}
                />
                <div className="wrapper">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="30"
                    viewBox="0 0 31 30"
                    fill="none"
                  >
                    <path
                      d="M14.25 25H8.625C6.725 25 5.10417 24.3458 3.7625 23.0375C2.42083 21.7208 1.75 20.1167 1.75 18.225C1.75 16.6 2.2375 15.15 3.2125 13.875C4.19583 12.6 5.47917 11.7875 7.0625 11.4375C7.5875 9.52083 8.62917 7.97083 10.1875 6.7875C11.7542 5.59583 13.525 5 15.5 5C17.9417 5 20.0083 5.85 21.7 7.55C23.4 9.24167 24.25 11.3083 24.25 13.75C25.6917 13.9167 26.8833 14.5417 27.825 15.625C28.775 16.6917 29.25 17.9417 29.25 19.375C29.25 20.9417 28.7042 22.2708 27.6125 23.3625C26.5208 24.4542 25.1917 25 23.625 25H16.75V16.0625L18.75 18L20.5 16.25L15.5 11.25L10.5 16.25L12.25 18L14.25 16.0625V25Z"
                      fill="#999999"
                    />
                  </svg>
                </div>
                <p>Upload Image</p>
              </div>
            </div>
          </div>

          <div className="title_description">
            <p>Description 1</p>
            <Editor
              editorState={description2}
              onEditorStateChange={setDescription2}
              wrapperClassName="wrapper-class"
              toolbarClassName="toolbar-class"
              editorClassName="editor-class"
              toolbar={{
                options: ["inline", "textAlign", "link", "blockType", "list"],
              }}
            />
          </div>
        </div>
        <div className="edit_info">
          <div className="title_info d-flex justify-content-between">
            {/* <div className="title_block">
              <p>Title</p>
              <input
                type="text"
                name="title"
                id=""
                className="info"
                value={formData.title}
                onChange={handleInput}
              />
            </div> */}
            <div className="image_container image_full_container">
              {file3 && <img src={`/public/${file3}`} alt="Selected" />}
              <div
                className="upload"
                onClick={() => imageFile3.current?.click()}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, "file3")}
                  style={{ display: "none" }}
                  id="imageInput"
                  ref={imageFile3}
                />
                <div className="wrapper">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="30"
                    viewBox="0 0 31 30"
                    fill="none"
                  >
                    <path
                      d="M14.25 25H8.625C6.725 25 5.10417 24.3458 3.7625 23.0375C2.42083 21.7208 1.75 20.1167 1.75 18.225C1.75 16.6 2.2375 15.15 3.2125 13.875C4.19583 12.6 5.47917 11.7875 7.0625 11.4375C7.5875 9.52083 8.62917 7.97083 10.1875 6.7875C11.7542 5.59583 13.525 5 15.5 5C17.9417 5 20.0083 5.85 21.7 7.55C23.4 9.24167 24.25 11.3083 24.25 13.75C25.6917 13.9167 26.8833 14.5417 27.825 15.625C28.775 16.6917 29.25 17.9417 29.25 19.375C29.25 20.9417 28.7042 22.2708 27.6125 23.3625C26.5208 24.4542 25.1917 25 23.625 25H16.75V16.0625L18.75 18L20.5 16.25L15.5 11.25L10.5 16.25L12.25 18L14.25 16.0625V25Z"
                      fill="#999999"
                    />
                  </svg>
                </div>
                <p>Upload Image</p>
              </div>
            </div>
          </div>

          <div className="title_description">
            <p>Description 2</p>
            <Editor
              editorState={description3}
              onEditorStateChange={setDescription3}
              wrapperClassName="wrapper-class"
              toolbarClassName="toolbar-class"
              editorClassName="editor-class"
              toolbar={{
                options: ["inline", "textAlign", "link", "blockType", "list"],
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
