/** @format */

import React, { useEffect, useState } from 'react';

import {
  FilterContainer,
  PriceSelector,
  SortByElements,
  Types,
} from './filter.style';

import { useFilterContext } from '../../../context/filter.context';
import { builder_status, home_style, home_type, sort_by } from './type';
import { useLocation } from 'react-router-dom';
type FilterPropertyProps = {
  types?: 'idx' | 'sold' | 'builder_deals';
  totalCount?: number;
};

export default function FilterProperty({
  types,
  totalCount,
}: FilterPropertyProps) {
  const [filterContainers, setFilterContainers] = useState('idle');
  const location = useLocation();

  const { state, setProperty, reset } = useFilterContext();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const saleOrLease = queryParams.get(`sale_or_lease`);
    if (saleOrLease) {
      setProperty('sale_or_lease', saleOrLease);
    }
  }, []);
  const handleFilter = () => {
    setFilterContainers((prev: string) =>
      prev === 'filter' ? 'idle' : 'filter',
    );
  };

  const handleSortBy = () => {
    setFilterContainers((prev: string) =>
      prev === 'sortBy' ? 'idle' : 'sortBy',
    );
  };

  //handle set property
  const handleSetProperty = (
    property: string,
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLSelectElement, MouseEvent>,
  ) => {
    setProperty(property, (e.target as HTMLButtonElement).value);
    if (e.currentTarget?.getAttribute('data-sortDir')) {
      setProperty('sortDir', e.currentTarget.getAttribute('data-sortDir'));
    }
  };

  const handleMaxMinPrice = (value: string) => {
    if (value === '1') {
      setProperty('min_price', '0');
      setProperty('max_price', '500000');
    } else if (value === '2') {
      setProperty('min_price', '500000');
      setProperty('max_price', '1000000');
    } else if (value === '3') {
      setProperty('min_price', '1000000');
      setProperty('max_price', '1500000');
    }
  };
  useEffect(() => {
    if (types === 'builder_deals') {
      setProperty('sortBy', 'created_at');
    } else if (types === 'idx') {
      setProperty('sortBy', 'timestamp_idx');
    } else {
      setProperty('sortBy', 'sold_date');
    }
  }, []);

  return (
    <FilterContainer>
      <div className='SortingContainers'>
        <div className='SortingContainer filter'>
          {/* onClick={handleFilter} */}
          <div className='Filters' onClick={handleFilter}>
            {filterContainers !== 'filter' ? (
              <>
                {/* <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1076_3980)">
                    <path
                      d="M3.07692 8.61527C4.22398 8.61527 5.15385 7.6854 5.15385 6.53835C5.15385 5.3913 4.22398 4.46143 3.07692 4.46143C1.92987 4.46143 1 5.3913 1 6.53835C1 7.6854 1.92987 8.61527 3.07692 8.61527Z"
                      stroke="#0061DF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.07812 8.61539V19M3.07812 1V4.46154"
                      stroke="#0061DF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.9226 8.61527C18.0697 8.61527 18.9996 7.6854 18.9996 6.53835C18.9996 5.3913 18.0697 4.46143 16.9226 4.46143C15.7756 4.46143 14.8457 5.3913 14.8457 6.53835C14.8457 7.6854 15.7756 8.61527 16.9226 8.61527Z"
                      stroke="#0061DF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.9238 4.46154V1M16.9238 19V8.61539"
                      stroke="#0061DF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.0008 12.0767C11.1478 12.0767 12.0777 11.1468 12.0777 9.99978C12.0777 8.85272 11.1478 7.92285 10.0008 7.92285C8.8537 7.92285 7.92383 8.85272 7.92383 9.99978C7.92383 11.1468 8.8537 12.0767 10.0008 12.0767Z"
                      stroke="#0061DF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 1V7.92308M10 12.0769V19"
                      stroke="#0061DF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1076_3980">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg> */}
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M3.33333 15H6.66667C7.125 15 7.5 14.625 7.5 14.1667C7.5 13.7083 7.125 13.3333 6.66667 13.3333H3.33333C2.875 13.3333 2.5 13.7083 2.5 14.1667C2.5 14.625 2.875 15 3.33333 15ZM2.5 5.83333C2.5 6.29167 2.875 6.66667 3.33333 6.66667H16.6667C17.125 6.66667 17.5 6.29167 17.5 5.83333C17.5 5.375 17.125 5 16.6667 5H3.33333C2.875 5 2.5 5.375 2.5 5.83333ZM3.33333 10.8333H11.6667C12.125 10.8333 12.5 10.4583 12.5 10C12.5 9.54167 12.125 9.16667 11.6667 9.16667H3.33333C2.875 9.16667 2.5 9.54167 2.5 10C2.5 10.4583 2.875 10.8333 3.33333 10.8333Z'
                    fill='black'
                  />
                </svg>
                <p>Filters</p>
                <div className='drop-down'>
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M12 6L8 10L4 6'
                      stroke='#0061DF'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
              </>
            ) : (
              <svg
                width='22'
                height='22'
                viewBox='0 0 22 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M19 19L3 3M19 3L3 19'
                  stroke='#241B1C'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            )}
          </div>
          {filterContainers === 'filter' && (
            <div className='FilterContainer'>
              <div className='Filter'>
                <p className='Heading'>
                  Filters <span>({totalCount} results)</span>
                </p>
                <div className='button_group_filter'>
                  <p className='Reset' onClick={reset}>
                    Reset Filters
                  </p>
                  {/* <button
                    onClick={handleFilter}
                    className="close_button">
                    <img
                      src={Close}
                      alt="close_button"
                    />
                  </button> */}
                </div>
              </div>
              <div className='HouseType'>
                <h1>House Type</h1>
                <div className='Types'>
                  {home_type.map(({ home_type, type }, index) => (
                    <Types
                      key={index}
                      value={type[types]}
                      check={state.home_type === type[types]}
                      onClick={(e) => handleSetProperty('home_type', e)}
                    >
                      {home_type}
                    </Types>
                  ))}
                </div>
              </div>
              {types !== 'builder_deals' && (
                <div className='BuildingStatus'>
                  <h1>House Style</h1>
                  <div className='Types'>
                    {home_style.map(({ home_style, type }) => (
                      <Types
                        value={type[types]}
                        check={state.property_style === type[types]}
                        onClick={(e) => handleSetProperty('property_style', e)}
                      >
                        {home_style}
                      </Types>
                    ))}
                  </div>
                </div>
              )}
              {types === 'builder_deals' && (
                <div className='BuildingStatus'>
                  <h1>Building Status</h1>
                  <div className='Types'>
                    {builder_status.map(({ builder_status, type }) => (
                      <Types
                        value={type[types]}
                        check={state.property_type === type[types]}
                        onClick={(e) => handleSetProperty('property_type', e)}
                      >
                        {builder_status}
                      </Types>
                    ))}
                  </div>
                </div>
              )}
              <div className='PriceRange'>
                <h1>Price Range</h1>
                <div className='Types'>
                  <PriceSelector>
                    <div className='min_price'>
                      <select
                        name=''
                        id=''
                        onClick={(e) => handleSetProperty('min_price', e)}
                      >
                        <option value='No min'>No Min</option>
                        <option value='0'>0</option>
                        <option value='500000'>500</option>
                        <option value='1000000'>1M</option>
                        <option value='1500000'>1.5M</option>
                        <option value='2000000'>2M</option>
                        <option value='3000000'>3M</option>
                      </select>
                    </div>
                    <span>-</span>
                    <div className='max_price'>
                      <select
                        name=''
                        id=''
                        onClick={(e) => handleSetProperty('min_price', e)}
                      >
                        <option value='nomax'>No Max</option>
                        <option value='0'>0</option>
                        <option value='500000'>500</option>
                        <option value='1000000'>1M</option>
                        <option value='1500000'>1.5M</option>
                        <option value='2000000'>2M</option>
                        <option value='3000000'>3M</option>
                      </select>
                    </div>
                  </PriceSelector>
                  <Types
                    value='1'
                    onClick={(e) =>
                      handleMaxMinPrice((e.target as HTMLButtonElement).value)
                    }
                  >
                    0-500
                  </Types>
                  <Types
                    value='2'
                    onClick={(e) =>
                      handleMaxMinPrice((e.target as HTMLButtonElement).value)
                    }
                  >
                    500-1M
                  </Types>
                  <Types
                    value='3'
                    onClick={(e) =>
                      handleMaxMinPrice((e.target as HTMLButtonElement).value)
                    }
                  >
                    1M-1.5M
                  </Types>
                </div>
              </div>
              <div className='Combo-bedBath'>
                <div className='BedRooms'>
                  <h1>Bed Rooms</h1>
                  <div className='Types'>
                    <Types
                      value=''
                      check={state.bedroom === ''}
                      onClick={(e) => handleSetProperty('bedroom', e)}
                    >
                      Any
                    </Types>
                    <Types
                      value='1'
                      check={state.bedroom === '1'}
                      onClick={(e) => handleSetProperty('bedroom', e)}
                    >
                      1+
                    </Types>
                    <Types
                      value='2'
                      check={state.bedroom === '2'}
                      onClick={(e) => handleSetProperty('bedroom', e)}
                    >
                      2+
                    </Types>
                    <Types
                      value='3'
                      check={state.bedroom === '3'}
                      onClick={(e) => handleSetProperty('bedroom', e)}
                    >
                      3+
                    </Types>
                    <Types
                      value='4'
                      check={state.bedroom === '4'}
                      onClick={(e) => handleSetProperty('bedroom', e)}
                    >
                      4+
                    </Types>
                    <Types
                      value='5'
                      check={state.bedroom === '5'}
                      onClick={(e) => handleSetProperty('bedroom', e)}
                    >
                      5+
                    </Types>
                  </div>
                </div>
                <div className='BathRooms'>
                  <h1>Bath Rooms</h1>
                  <div className='Types'>
                    <Types
                      value=''
                      check={state.bathroom === ''}
                      onClick={(e) => handleSetProperty('bathroom', e)}
                    >
                      Any
                    </Types>
                    <Types
                      value='1'
                      check={state.bathroom === '1'}
                      onClick={(e) => handleSetProperty('bathroom', e)}
                    >
                      1+
                    </Types>
                    <Types
                      value='2'
                      check={state.bathroom === '2'}
                      onClick={(e) => handleSetProperty('bathroom', e)}
                    >
                      2+
                    </Types>
                    <Types
                      value='3'
                      check={state.bathroom === '3'}
                      onClick={(e) => handleSetProperty('bathroom', e)}
                    >
                      3+
                    </Types>
                    <Types
                      value='4'
                      check={state.bathroom === '4'}
                      onClick={(e) => handleSetProperty('bathroom', e)}
                    >
                      4+
                    </Types>
                    <Types
                      value='5'
                      check={state.bathroom === '5'}
                      onClick={(e) => handleSetProperty('bathroom', e)}
                    >
                      5+
                    </Types>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <p className='Heading_mobile'>
          Filters <span>({totalCount} results)</span>
        </p>
        <div className='SortingContainer sorte'>
          <div className='Filters' onClick={handleSortBy}>
            {filterContainers !== 'sortBy' ? (
              <>
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none">
                  <path
                    d="M3.33333 15H6.66667C7.125 15 7.5 14.625 7.5 14.1667C7.5 13.7083 7.125 13.3333 6.66667 13.3333H3.33333C2.875 13.3333 2.5 13.7083 2.5 14.1667C2.5 14.625 2.875 15 3.33333 15ZM2.5 5.83333C2.5 6.29167 2.875 6.66667 3.33333 6.66667H16.6667C17.125 6.66667 17.5 6.29167 17.5 5.83333C17.5 5.375 17.125 5 16.6667 5H3.33333C2.875 5 2.5 5.375 2.5 5.83333ZM3.33333 10.8333H11.6667C12.125 10.8333 12.5 10.4583 12.5 10C12.5 9.54167 12.125 9.16667 11.6667 9.16667H3.33333C2.875 9.16667 2.5 9.54167 2.5 10C2.5 10.4583 2.875 10.8333 3.33333 10.8333Z"
                  // fill="#0061DF"
                  />
                </svg> */}
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M4.59619 2C4.59619 1.58579 4.26041 1.25 3.84619 1.25C3.43198 1.25 3.09619 1.58579 3.09619 2V4.43689C2.02802 4.75867 1.25 5.75002 1.25 6.92308C1.25 8.09615 2.02802 9.08749 3.09619 9.40927V18C3.09619 18.4142 3.43198 18.75 3.84619 18.75C4.26041 18.75 4.59619 18.4142 4.59619 18V9.40925C5.66433 9.08745 6.44231 8.09612 6.44231 6.92308C6.44231 5.75004 5.66433 4.75871 4.59619 4.43692V2ZM2.75 6.92308C2.75 6.31769 3.24076 5.82693 3.84615 5.82693C4.45154 5.82693 4.94231 6.31769 4.94231 6.92308C4.94231 7.52847 4.45154 8.01924 3.84615 8.01924C3.24076 8.01924 2.75 7.52847 2.75 6.92308Z'
                    fill='black'
                  />
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M16.9014 2C16.9014 1.58579 16.5656 1.25 16.1514 1.25C15.7372 1.25 15.4014 1.58579 15.4014 2V4.43675C14.3329 4.75837 13.5547 5.74984 13.5547 6.92308C13.5547 8.09632 14.3329 9.0878 15.4014 9.40942V18C15.4014 18.4142 15.7372 18.75 16.1514 18.75C16.5656 18.75 16.9014 18.4142 16.9014 18V9.4091C17.9693 9.08715 18.747 8.09594 18.747 6.92308C18.747 5.75022 17.9693 4.75902 16.9014 4.43706V2ZM16.1529 8.01923C16.7573 8.01814 17.247 7.52779 17.247 6.92308C17.247 6.31769 16.7562 5.82693 16.1508 5.82693C15.5455 5.82693 15.0547 6.31769 15.0547 6.92308C15.0547 7.52847 15.5455 8.01924 16.1508 8.01924C16.1507 8.01924 16.151 8.01924 16.1508 8.01924C16.1513 8.01924 16.1524 8.01923 16.1529 8.01923Z'
                    fill='black'
                  />
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M10.752 2C10.752 1.58579 10.4162 1.25 10.002 1.25C9.58774 1.25 9.25195 1.58579 9.25195 2V7.51396C8.18403 7.83589 7.40625 8.82712 7.40625 10C7.40625 11.1729 8.18403 12.1641 9.25195 12.486V18C9.25195 18.4142 9.58774 18.75 10.002 18.75C10.4162 18.75 10.752 18.4142 10.752 18V12.4863C11.8203 12.1647 12.5986 11.1732 12.5986 10C12.5986 8.82679 11.8203 7.83533 10.752 7.51369V2ZM10.0024 8.90385C9.39701 8.90385 8.90625 9.39461 8.90625 10C8.90625 10.6054 9.39701 11.0962 10.0024 11.0962C10.6078 11.0962 11.0986 10.6054 11.0986 10C11.0986 9.39461 10.6078 8.90385 10.0024 8.90385Z'
                    fill='black'
                  />
                </svg>
                <p>Sort Buy</p>
                <div className='drop-down'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                  >
                    <path
                      d='M12 6L8 10L4 6'
                      stroke='#0061DF'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
              </>
            ) : (
              <svg
                width='22'
                height='22'
                viewBox='0 0 22 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M19 19L3 3M19 3L3 19'
                  stroke='#241B1C'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            )}
          </div>
          {filterContainers === 'sortBy' && (
            <div className='SortBy'>
              {/* <div className="close_sortBy">
                <button
                  onClick={handleSortBy}
                  className="close_button">
                  <img
                    src={Close}
                    alt="close_button"
                  />
                </button>
              </div> */}
              <div className='sortByContainer'>
                {sort_by.map(({ sort_by, type, order }) => (
                  <SortByElements
                    key={sort_by}
                    value={type[types]}
                    data-sortDir={order}
                    check={state.sortDirByName === sort_by}
                    onClick={(e) => {
                      handleSetProperty('sortBy', e);
                      setProperty('sortDirByName', sort_by);
                    }}
                  >
                    {sort_by}
                  </SortByElements>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </FilterContainer>
  );
}
