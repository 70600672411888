/** @format */

import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const Container = styled.div`
  font-family: 'Syncopate';
  // background-color: ${(props) => props.theme.colors.primary};
  background-color: #000;
  width: min(100%, 120rem);
  margin: 3rem auto 0;
  padding: 2rem 0 1rem;
  h1,
  p {
    font-family: 'Syncopate';
  }
  .heading {
    margin-bottom: 1rem;
  }
  h1 {
    color: ${(props) => props.theme.colors.text};
    font-size: clamp(1.5rem, 2vw, 2rem);
  }
  .wrapper {
    padding: 0.75rem;
  }
`;
export const FeatureCard = styled.div`
  background-color: ${(props) =>
    props?.theme?.colors?.background
      ? props.theme.colors.background
      : '#f8f9fa'};
  position: relative;
  padding-bottom: 1rem;
  width: 100%;
  min-height: 25rem;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  .image_wrapper {
    height: 17.681rem;
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .image_box {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .svg_box {
      width: 2rem;
      height: 2rem;
    }
  }

  /* img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */
  .property_info {
    color: ${(props) =>
      props?.theme?.colors?.primary
        ? props.theme.colors.primary
        : 'var(--primary)'};
    text-align: center;
    font-size: 1.063rem;
    margin: 0;
  }
  .view_button {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    a {
      border: 1px solid
        ${(props) =>
          props?.theme?.colors?.primary
            ? props.theme.colors.primary
            : 'var(--primary)'};
      color: ${(props) =>
        props?.theme?.colors?.primary
          ? props.theme.colors.primary
          : 'var(--primary)'};
      text-decoration: none;
      align-items: center;
      padding-block: 0.55rem;
      padding-inline: 0.65rem;
    }
  }
  .for_sale {
    position: absolute;
    right: 0;
    background-color: black;

    padding: 0.25rem;
    text-transform: uppercase;
    p {
      font-size: clamp(0.25rem, 1vw, 1rem);
      color: ${(props) =>
        props?.theme?.colors?.text ? props.theme.colors.text : 'white'};
      margin: 0;
    }
  }
  &:hover {
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: ${(props) =>
        props?.theme?.colors?.background
          ? props.theme.colors.background
          : '#f8f9fa'};
      opacity: 0.8;
    }
    .view_button {
      opacity: 1;
    }
  }
`;

export const Nevigation = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  .navigation {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    cursor: pointer;
  }
  .bar {
    height: 1rem;
    width: 0.15rem;
    background-color: ${(props) =>
      props?.theme?.colors?.background
        ? props.theme.colors.background
        : 'black'};
  }
`;

export const ViewAll = styled(Link)`
  display: block;
  margin: 2rem auto 1rem;
  background-color: ${(props) => props.theme.colors.text};
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
  width: fit-content;
  padding-block: 1rem;
  padding-inline: 1.5rem;
  cursor: pointer;
`;
